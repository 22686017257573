/********** START CALENDAR CSS **********/
.ui-datepicker td:first-child {
  pointer-events: none !important;
}

.ui-datepicker td:first-child a,
.ui-datepicker td:first-child span {
  background-color: #d8d8d8 !important;
  color: #000 !important;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 0px !important;
  top: 0px !important;
}

.ui-datepicker .ui-datepicker-prev {
  left: 0px !important;
  top: 0px !important;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 0px !important;
  top: 0px !important;
}

.ui-datepicker .ui-datepicker-next {
  top: 0px !important;
}

td.ui-datepicker-other-month {
  display: invisiable;
}

td.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled {
  visibility: hidden !important;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  background-image: none;
  opacity: none;
}

.ui-datepicker-today a.ui-state-default {
  background-color: #fff !important;
  font-weight: normal !important;
  color: #000;
}

a.ui-state-default {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px !important;
  background: #fff !important;
  font-weight: normal;
  color: #000000 !importnat;
  text-align: center !important;
  border: 1px solid #333 !important;
  padding: 8px !important;
  margin: -0.7px;
}

.ui-datepicker td span {
  padding: 8px !important;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px !important;
  color: #000000 !important;
  background-color: #d3d3d3 !important;
  border: 1px solid #333 !important;
  margin: -0.7px;
}

.ui-widget-content {
  border: none !important;
  border-collapse: collapse !important;
  margin-top: 0px !important;
  padding: 0px !important;
  margin-left: 0 auto !important;
}

.ui-widget-header {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 100%;
  border: none !important;
  background: none !important;
  color: #333366 !important;
  font-weight: normal !important;
}

.ui-datepicker-month,
.ui-datepicker-year {
  font-weight: normal;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.ui-datepicker th {
  text-align: center;
  font-weight: bold !important;
  color: #000000;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  font-family: "HelveticaNeueW02-75Blod", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-size: 11px !important;
}

.ui-datepicker .ui-datepicker-title {
  text-align: inherit !important;
}

.ui-datepicker td {
  padding: 0px !important;
}

.ui-datepicker-group {
  border-collapse: collapse !important;
  border: 1px solid #333366;
  padding: 20px 8px 40px 20px;
  margin: 0px 20px 0px 0px;
  width: 283px !important;
  height: 270px !important;
  text-align: center;
}

.ui-datepicker-multi .ui-datepicker-group table {
  margin-right: 20px !important;
}

.ui-datepicker-inline {
  display: inline-block !important;
}

a.ui-state-default.ui-state-active:after {
  display: block !important;
  border-left: 10px solid transparent !important;
  border-bottom: 10px solid #fff !important;
  position: absolute;
  margin-left: 0px;
  margin-top: 0px;
}

.ui-datepicker td:first-child a.ui-state-default.ui-state-active:after,
.ui-datepicker td:first-child span.ui-state-default.ui-state-active:after {
  content: "" !important;
  display: inline !important;
  border-left: 0px solid transparentparent !important;
  border-bottom: 0px solid #fff !important;
  border-top-color: rgba(0, 0, 0, 0.2) !important;
  margin-bottom: 0px;
  margin-right: 0px;
}

a.ui-state-default:after {
  display: none !important;
}

.ui-widget {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.ui-state-disabled {
  opacity: unset !important;
  background-image: none;
  text-align: center;
}

.ui-datepicker td:first-child a,
.ui-datepicker td:first-child span {
  background-color: #d8d8d8 !important;
}

.ui-datepicker-unselectable .ui-state-default {
  background: unset !important;
  text-align: center;
}

a.ui-state-default {
  color: #000 !important;
  background-color: #d3d3d3 !important;
}

.holiday a.ui-state-default {
  background-color: #d3d3d3 !important;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  width: unset !important;
  height: unset !important;
  border: none !important;
  right: 0px !important;
  background-color: #fff !important;
  background: none !important;
}

.ui-datepicker .ui-datepicker-next .ui-icon {
  background: url(./click-n-ship/images/calendar-arrow.png) no-repeat;
  width: 20px;
  height: 25px;
  top: 139px;
  background-size: 15px 25px;
}

.ui-datepicker .ui-datepicker-prev .ui-icon {
  background: url(./click-n-ship/images/calendar-arrow.png) no-repeat;
  width: 25px;
  height: 25px;
  top: 139px;
  left: -65px;
  transform: rotate(180deg);
}

a.ui-datepicker-prev.ui-corner-all.ui-state-disabled .ui-icon {
  background: none;
}

a.ui-datepicker-next.ui-corner-all.ui-state-disabled .ui-icon {
  background: none;
  transform: rotate(180deg);
  background-size: 17px 25px;
  left: 35px;
}

.ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi-3.ui-datepicker-multi {
  width: 100% !important;
  display: inline-block !important;
  margin: auto 50px !important;
}

.ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi-2.ui-datepicker-multi {
  width: auto !important;
}

.ui-widget-header {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ui-datepicker-multi .ui-datepicker-group {
  height: 330px !important;
}

.ui-widget-header {
  font-family: "HelveticaNeueW02-75Blod", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  font-size: 14px !important;
  color: #333366 !important;
}

.ui-datepicker-unselectable .ui-state-default {
  background: #d3d3d3 !important;
  color: #000000 !important;
  font-size: 16px !important;
}

a.ui-state-default {
  color: #000000 !important;
  font-size: 16px !important;
}

td.ui-datepicker-week-end.ui-datepicker-other-month.ui-datepicker-unselectable.ui-state-disabled {
  color: #e71921;
}

.ui-datepicker .ui-datepicker-title {
  line-height: 1em;
  margin-bottom: 20px;
  text-align: center;
}

.ui-datepicker-today a.ui-state-default {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
  background-color: #fff !important;
  color: #000;
}

a.ui-datepicker-prev.ui-corner-all.ui-state-disabled .ui-icon {
  background: none;
}

.ui-datepicker-multi .ui-datepicker-group {
  height: 330px !important;
  margin-bottom: 20px;
}

div div .ui-datepicker-next .ui-icon {
  background: none;
}

a.ui-datepicker-prev.ui-corner-all.ui-state-disabled .ui-icon {
  transform: rotate(0deg);
  left: -55px;
}

.selected-date a.ui-state-default.ui-state-highlight {
  background-color: #333366 !important;
  color: #fff !important;
}

.ui-datepicker td a.ui-state-highlight {
  color: #333366 !important;
  font-size: 16px !important;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

.selected-date a {
  color: #fff !important;
  background-color: #333366 !important;
}

.ui-state-default ~ .popover.top {
  display: none !important;
}

.ui-state-default.ui-state-active ~ .popover.top {
  display: block !important;
  margin: -107px 0px 0px -102px;
}
.ui-widget button {
  font-size: 2em !important;
}

.ui-datepicker .ui-datepicker-next .ui-icon {
  left: 40px;
}

.ui-datepicker-multi-13.ui-datepicker-multi {
  width: 83em !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  height: 40px !important;
  background-color: #fff !important;
}

.selected-date .ui-state-default {
  background-color: #333366 !important;
  color: #fff !important;
}

.ui-datepicker-unselectable.ui-state-disabled .ui-state-default {
  background-color: #d8d8d8 !important;
}

.ui-datepcker-arrow-down::before {
  position: absolute;
  top: 328px;
  right: 16px;
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  border-style: solid;
  border-color: transparent #000 #000 transparent;
  z-index: 99;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transfor: rotate(45deg);
  background-color: white;
  z-index: -1;
  border-width: 1px;
}

div#ui-datepicker-div.single-input-cal {
  border: 1px solid #595959 !important;
  width: 300px !important;
  height: 340px !important;
  z-index: 999 !important;
}

div#ui-datepicker-div.single-input-cal .ui-datepicker-title {
  text-align: center !important;
  width: 90% !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
}

div#ui-datepicker-div.single-input-cal .ui-icon {
  top: 22px !important;
  left: 15px !important;
}

div#ui-datepicker-div.single-input-cal table {
  width: 87% !important;
  margin-left: 20px !important;
  margin-top: 12px !important;
}

div#ui-datepicker-div.single-input-cal .ui-widget-header {
  width: 259px !important;
}

.ui-datepicker-arrow-down::before {
  position: absolute;
  top: 328px;
  right: 16px;
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  border-style: solid;
  border-color: transparent #000 #000 transparent;
  z-index: 99;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transfor: rotate(45deg);
  background-color: white;
  z-index: -1;
  border-width: 1px;
}

div#ui-datepicker-div.single-input-cal.ui-corner-all {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.ui-top-cal-arrow::before {
  position: absolute;
  top: 328px;
  right: 10px;
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  border-style: solid;
  border-color: transparent #000 #000 transparent;
  z-index: 99;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transfor: rotate(45deg);
  background-color: white;
  z-index: -1;
  border-width: 1px;
}

.ui-bottom-cal-arrow::before {
  position: absolute;
  top: -10px;
  right: 10px;
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  border-style: solid;
  border-color: transparent #000 #000 transparent;
  z-index: 99;
  transform: rotate(224deg);
  -ms-transform: rotate(45deg);
  -webkit-transfor: rotate(155deg);
  background-color: white;
  z-index: -1;
  border-width: 1px;
}

@media only screen and (min-width: 1057px) {
  .ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi-12.ui-datepicker-multi {
    width: auto !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 370px) {
  .ui-datepicker .ui-datepicker-next .ui-icon {
    left: 0px !important;
  }

  .ui-datepicker .ui-datepicker-prev .ui-icon {
    position: relative;
    left: -53px;
  }
}

@media only screen and (max-width: 1057) {
  .ui-datepicker .ui-datepicker-next .ui-icon {
    left: 0px !important;
    width: 14px;
    height: 25px;
    top: 0px;
  }

  .ui-datepicker .ui-datepicker-prev .ui-icon {
    left: -20px !important;
  }
}

@media only screen and (max-width: 1057px) and (min-width: 768px) {
  .ui-datepicker-multi-13.ui-datepicker-multi {
    width: 56em !important;
  }

  .ui-datepicker-inline.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all.ui-datepicker-multi-12.ui-datepicker-multi {
    padding: 0px 0px 0px 40px !important;
    width: 88% !important;
    padding-left: inherit !important;
  }

  .ui-datepicker .ui-datepicker-next .ui-icon {
    left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .ui-state-default.ui-state-active ~ .popover.top {
    display: #000 !important;
    margin: 0px 0px 0px 0px;
  }

  .ui-widget.ui-widget-content {
    margin-top: 30px !important;
    height: 280px !important;
  }

  .ui-datepicker-inline {
    width: 85% !important;
    display: inline-block !important;
  }

  .ui-datepicker .ui-datepicker-next .ui-icon {
    position: relative;
    float: left;
    top: 0px;
    left: 0px;
  }

  .ui-datepicker .ui-datepicker-prev .ui-icon {
    position: relative;
    left: 10px;
    top: 0px;
  }

  .ui-datepicker .ui-datepicker-title {
    text-align: center !important;
  }

  a.ui-datepicker-prev.ui-corner-all.ui-state-disabled .ui-icon {
    left: 18px !important;
  }

  div#ui-datepicker-div.single-input-cal .ui-datepicker-title {
    width: 93%;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui-datepicker-multi-13.ui-datepicker-multi {
    width: 84em !important;
  }

  a.ui-state-default {
    color: #000000 !important;
    background-color: #d8d8d8 !important;
  }

  .holiday a.ui-state-default {
    color: #000000 !important;
    background-color: #d8d8d8 !important;
  }

  .ui-datepicker .ui-datepicker-next .ui-icon {
    left: 75px;
  }

  .ui-state-disabled,
  .ui-widget-content .ui-state-disabled,
  .ui-widget-header .ui-state-disabled {
    background-image: none;
    opacity: 1 !important;
  }
}
