/* Banner carousel styling */
#bannerCarouselWrapper,
#bannerCarouselWrapper ol.carousel-indicators li.active,
#bannerCarouselWrapper ol.carousel-indicators li:not(.active),
#bannerCarouselWrapper button.carousel-control-prev,
#bannerCarouselWrapper button.carousel-control-next {
  background-color: rgb(247, 247, 247);
}

#bannerCarouselWrapper {
  box-shadow: 0px 3px 6px;
  border-radius: 5px;
  margin-top: 42px;
  min-height: 165px;
  padding-bottom: 25px;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
}

@media (max-width: 767px) {
  #bannerCarouselWrapper {
    margin-bottom: 42px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  #bannerCarouselWrapper {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (max-width: 575px) {
  #bannerCarouselWrapper {
    padding-left: 39px;
    padding-right: 39px;
  }
}

#bannerCarouselWrapper ol.carousel-indicators li {
  margin-left: 5px;
  margin-right: 5px;
}

#bannerCarouselWrapper ol.carousel-indicators li.active {
  background-image: url("../../click-n-ship/images/dark-gray-circle.svg");
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
}

#bannerCarouselWrapper ol.carousel-indicators li:not(.active) {
  background-image: url("../../click-n-ship/images/dark-gray-circle.svg");
  background-repeat: no-repeat;
  height: 17px;
  width: 17px;
}

#bannerCarouselWrapper button.carousel-control-prev {
  background-image: url("../../click-n-ship/images/left-arrow-blue.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 5px;
  opacity: 0.9;
  width: 100px;
}

#bannerCarouselWrapper button.carousel-control-next {
  background-image: url("../../click-n-ship/images/right-arrow-blue.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 5px;
  opacity: 0.9;
  width: 100px;
}

@media (min-width: 576px) and (max-width: 767px) {
  #bannerCarouselWrapper button.carousel-control-prev,
  #bannerCarouselWrapper button.carousel-control-next {
    width: 70px;
  }
}

@media (max-width: 575px) {
  #bannerCarouselWrapper button.carousel-control-prev,
  #bannerCarouselWrapper button.carousel-control-next {
    width: 39px;
  }
}

#bannerCarouselWrapper .carousel-inner {
  margin-bottom: 35px;
  text-align: center;
}

#bannerCarouselWrapper .info-icon-without-popover {
  margin-top: -13px;
}

#bannerCarouselWrapper .bannerCarouselText {
  color: #000000;
  display: inline;
  font-size: 16px;
}

/* Styling for individual banners within the carousel */
#redirectToNewCnsText,
#redirectToNewCnsLink,
#selfEnrollmentText,
#selfEnrollmentLink {
  padding-left: 15px;
  padding-right: 15px;
}

.banner-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  #redirectToNewCnsText,
  #selfEnrollmentText {
    padding-bottom: 15px;
  }
}
