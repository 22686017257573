.hideMapSection {
  display: none;
}

#connectMapViewDivId {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  flex: 0 0 95%;
  max-width: 95%;
}

#connectMapViewDivId.dropOffLocationsErrorPadding {
  top: 30px;
}

#Step5ConnectMapWrapper {
  position: relative;
  width: 800px;
  height: 500px;
  margin-left: 0px;
  margin-right: 15px;
}

#PreferencesConnectMapWrapper {
  position: relative;
  width: 800px;
  height: 500px;
  margin-top: 20px;
  margin-left: 15px;
}

#LocationsConnectMapWrapper {
  position: relative;
  width: 800px;
  height: 500px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
}

.connnectMapFacilityName {
  color: #333366;
  text-decoration: underline;
}

.compassIcon {
  width: 24px;
  height: 24px;
  background-image: url(./../../../click-n-ship/images/compass.svg);
  background-repeat: no-repeat;
  display: inline-block;
  cursor: default !important;
  right: 20px;
  color: #336;
  position: absolute;
  /* This looks off center on our locals, but is correct in higher environments */
  top: 61px;
}

@media only screen and (max-width: 767px) {
  .compassIcon {
    /* This looks off center on our locals, but is correct in higher environments */
    top: 57px;
  }
}

.compassIcon:hover {
  color: #336;
  opacity: 1;
  text-decoration: none;
}

.popupSmallScreenInfoWrapper {
  display: block;
}

.popupSmallScreenLocationInfo {
  display: block;
  margin-left: 15px;
}

.popupSmallScreenLocationHoursWrapper {
  display: block;
  margin-left: 30px;
}

.popupSmallScreenLocationHours {
  margin-left: -30px;
}

/* START - Modifications to Esri classes */

#connectMapViewDivId .esri-popup__main-container {
  border: 2px solid #999999;
  border-radius: 16px;
  min-width: 435px;
}

@media only screen and (max-width: 767px) {
  #connectMapViewDivId .esri-popup__main-container {
    min-width: 100%;
  }
}

#connectMapViewDivId .esri-popup--shadow {
  box-shadow: 0 0px 0px;
}

#connectMapViewDivId .esri-popup__header {
  max-height: 0px;
}

#connectMapViewDivId .esri-popup__content {
  margin-top: 30px;
}

#connectMapViewDivId .esri-widget *:focus {
  outline: 0px;
}

#connectMapViewDivId .esri-view-user-storage,
#connectMapViewDivId .esri-popup__footer,
#connectMapViewDivId .esri-zoom,
#connectMapViewDivId .esri-icon-zoom-in-magnifying-glass,
#connectMapViewDivId .esri-popup__button--dock,
#connectMapViewDivId .esri-component.esri-attribution.esri-widget {
  display: none;
}

/* END - Modifications to Esri classes */
