/********** START SPINNER GENERAL STYLING CSS **********/

.crossfade {
  visibility: hidden;
}

/***** START WHITE SPINNER *****/
.gray-overlay {
  color: white;
  background-color: #031533;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

.white-spinner-container .spinner-content {
  display: block;
  position: relative;
  background: #333366;
  opacity: 1;
  z-index: 1;
  padding: 30px 30px 30px 30px;
}

.white-spinner-container .spinner-content h5 {
  font-size: 18px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin-top: 0px;
  color: #ffffff;
  padding-bottom: 10px;
}

.white-spinner-container {
  position: fixed;
  top: 41%;
  text-align: center;
  z-index: 99999;
}

.white-spinner-container p {
  color: #fff;
  padding-top: 5px;
}

.white-spinner-wrapper {
  margin: 36px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.white-spinner .spinner {
  text-align: center;
  font-size: 10px;
}

.white-spinner .bar-1,
.white-spinner .bar-2,
.white-spinner .bar-3,
.white-spinner .bar-4,
.white-spinner .bar-5 {
  display: inline-block;
  background-color: #333366;
  border: 1px solid #ffffff;
  width: 45px;
  height: 15px;
  opacity: 1;
  transform: skew(-9deg);
  -webkit-transform: skew(-9deg);
  -moz-transform: skew(-9deg);
  -o-transform: skew(-9deg);
  -webkit-animation: whitebar-stretchdelay 1.2s infinite ease-in-out;
  animation: whitebar-stretchdelay 1.2s infinite ease-in-out;
}

.white-spinner .bar-2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.white-spinner .bar-3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.white-spinner .bar-4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.white-spinner .bar-5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes whitebar-stretchdelay {
  0%,
  40%,
  100% {
    background-color: #333366;
  }
  20% {
    background-color: #ffffff;
  }
}

@keyframes whitebar-stretchdelay {
  0%,
  40%,
  100% {
    background-color: #333366;
  }
  20% {
    background-color: #ffffff;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .white-spinner-wrapper {
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) {
  .white-spinner-wrapper {
    width: 240px;
  }
}

/***** START BLUE SPINNER *****/
.white-overlay {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.visible {
  opacity: 1 !important;
}

.blue-spinner-container .spinner-content {
  display: block;
  position: relative;
  background: #ffffff;
  opacity: 1;
  z-index: 1000;
  padding: 30px 30px 30px 30px;
}

.blue-spinner-container .spinner-content h5 {
  font-size: 18px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin-top: 0px;
  color: #595959;
  padding-bottom: 10px;
}

.blue-spinner-container {
  position: fixed;
  top: 41%;
  text-align: center;
  z-index: 999;
}

.blue-spinner-container p {
  color: #595959;
  padding-top: 5px;
}

.blue-spinner-wrapper {
  margin: 36px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.blue-spinner .spinner {
  text-align: center;
  font-size: 10px;
}

.blue-spinner .bar-1,
.blue-spinner .bar-2,
.blue-spinner .bar-3,
.blue-spinner .bar-4,
.blue-spinner .bar-5 {
  display: inline-block;
  background-color: #ededed;
  border: 1px solid #ededed;
  width: 45px;
  height: 15px;
  opacity: 1;
  transform: skew(-9deg);
  -webkit-transform: skew(-9deg);
  -moz-transform: skew(-9deg);
  -o-transform: skew(-9deg);
  -webkit-animation: bluebar-stretchdelay 1.2s infinite ease-in-out;
  animation: bluebar-stretchdelay 1.2s infinite ease-in-out;
}

.blue-spinner .spinner .bar-2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.blue-spinner .spinner .bar-3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.blue-spinner .spinner .bar-4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.blue-spinner .spinner .bar-5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes bluebar-stretchdelay {
  0%,
  40%,
  100% {
    background-color: #ededed;
  }
  20% {
    background-color: #333366;
  }
}

@keyframes bluebar-stretchdelay {
  0%,
  40%,
  100% {
    background-color: #ededed;
  }
  20% {
    background-color: #333366;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .blue-spinner-wrapper {
    justify-content: flex-start;
  }
}
