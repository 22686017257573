/********************/
/* CLICK-N-SHIP CSS */
/********************/

/* START GENERAL STYLING */
h2.normal {
  padding-top: 25px;
}

h2 + h2 {
  padding-top: 22px;
}

.horizontal-line-container {
  padding-top: 30px;
  margin-top: 0px;
}

.Step_One_Container,
.Step_Two_Container,
.Step_Three_Container,
.Step_Four_Container,
.Step_Five_Container,
.Step_Six_Container,
.Step_Seven_Container,
.Step_Eight_Container,
.Step_Nine_Container,
.Step_Ten_Container,
.Preferences_Greeting_Container,
.Package_Options_Container,
.Shipment_Notifications_Container,
.Notify_Recipient_Shipping_Container,
.Print_Settings_Container,
.Reference_Number_Container,
.Return_Address_Container,
.Shipping_Zip_Code_Container,
.SCAN_Form_Container {
  padding-top: 20px;
}

.go-to-wrapper {
  padding-top: 30px;
  padding-bottom: 5px;
}

.go-to-wrapper button.dropdown-items-wrapper {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.popover-wrapper ul,
.modal-body ul {
  padding-left: 15px;
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  #address-book {
    max-width: none;
    width: 92%;
  }
}

@media (min-width: 1200px) {
  #address-book {
    max-width: 1140px;
  }
}

#address-book .modal-body ul {
  padding-left: 0px;
}

@media only screen and (max-width: 767px) {
  .Step_One_Container,
  .Step_Two_Container,
  .Step_Three_Container,
  .Step_Four_Container,
  .Step_Five_Container,
  .Step_Six_Container,
  .Step_Seven_Container,
  .Step_Eight_Container,
  .Step_Nine_Container,
  .Step_Ten_Container,
  .Preferences_Greeting_Container,
  .Package_Options_Container,
  .Shipment_Notifications_Container,
  .Notify_Recipient_Shipping_Container,
  .Print_Settings_Container,
  .Reference_Number_Container,
  .Return_Address_Container,
  .Shipping_Zip_Code_Container,
  .SCAN_Form_Container {
    padding-top: 5px;
  }

  ol {
    list-style: decimal;
  }

  .button-wrapper {
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 949px) {
  .click-n-ship-main-header h1 {
    width: 100%;
    padding-top: 35px;
  }
}

#tracking-notifications-id,
#ship-from-other-zip-id,
#batch-order-id,
#parcel-select-id p,
#email-notifications-id,
#text-message-notifications-id,
#expected-delivery-updates-id,
#day-of-delivery-updates-id,
#package-delivered-id,
#available-for-pickup-id,
#delivery-exception-updates-id,
#package-in-transit-updates-id,
#send-recipient-email-id,
#hold-for-pickup-id,
#notify-addressee-id,
#notify-me-id {
  color: #333366;
}

#cns-message-wrap {
  background: url("./click-n-ship/images/alert-sprite@2x.png") no-repeat scroll
    20px 0 / 18px auto transparent;
  line-height: 20px;
  margin: 0 auto;
  max-width: 1280px;
  padding-left: 45px;
  padding-right: 20px;
  position: relative;
  top: 10px;
  background-size: 18px auto;
}

#cns-message {
  color: #e51e2b;
  font-size: 13px;
}

/* END GENERAL STYLING */

/* START STEP ONE: RETURN ADDRESS */

.form-group.required-field.step-one-shipping-zip {
  padding-left: 40px;
  padding-right: 40px;
}

.update-mailing-clear-changes-wrapper {
  display: none;
}

.save-address-book-wrapper {
  padding-top: 15px;
}

.step-one-standardized-address {
  padding-top: 25px;
  padding-bottom: 5px;
}

.standardize-address-header {
  color: #333366;
}

.use-address-standardized-address-wrapper,
.click-step-one-input-container {
  display: none;
}

h2.more-actions-header {
  padding-top: 40px;
}

#desired-updates {
  padding-left: 35px;
}

.desired-update-notification {
  padding-top: 5px;
}

.desired-update-notification .checkbox-wrap {
  padding-top: 5px;
}

.batch-order-text-container,
#batch-order-text-container-id,
.parcel-select-text-container,
#parcel-select-text-container-id,
#drop-off-location-fields {
  padding-left: 42px;
}
.parcel-select-text-container,
#parcel-select-text-container-id {
  display: block;
}
.step-one-tracking-notifications {
  padding-top: 20px;
}

.step-one-tracking-notifications label,
.step-one-different-address label {
  color: #333366;
  font-size: 14px;
}

.different-address-desc,
.batch-order-desc {
  padding-left: 29px;
}

#click-ship-message-counter,
#detailed-description-counter {
  color: #333366;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 13px;
  margin-left: 10px;
}

#step-one-text-message-input,
#step-one-email-input {
  padding-top: 10px;
  padding-left: 25px;
}

.send-notifications-wrapper {
  display: none;
  padding-left: 30px;
}

.user-entered-data.step-one-address span {
  padding-top: 0px;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 0px;
}

.user-entered-data.step-one-address span:first-child {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  line-height: 20px;
  color: #595959;
  margin: 0 0 0px;
  padding-bottom: 5px;
}

.link-edit {
  padding-top: 10px;
}

label.checkbox-text.vertical.save-address-book-checkbox {
  padding-top: 30px;
}

.tracking-notification-choices-wrapper {
  padding-left: 30px;
}

.tracking-notification-choices-wrapper .row + .row {
  padding-top: 5px;
}

.step-one-email-not {
  margin-bottom: 20px;
}

.step-one-different-address {
  padding-top: 10px;
}

@media only screen and (max-width: 767px) {
  .row.user-entered-data.step-one-address div + div {
    padding-top: 15px;
  }

  h2.more-actions-header {
    cursor: pointer;
  }

  h2.more-actions-header:not(.active) ~ div {
    display: none;
  }

  h2.more-actions-header:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    background-size: 22px 22px;
    width: 22px;
    background-repeat: no-repeat;
    background-position: 0 0;
    height: 25px;
    background-image: url("./click-n-ship/images/blue-caret-down.svg");
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: transform 0.2s ease-out;
  }

  h2.more-actions-header.active:after {
    background-image: url("./click-n-ship/images/blue-caret-down.svg");
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: transform 0.2s ease-out;
  }

  h2.more-actions-header.disabled:after {
    display: none;
  }

  .email-column-wrapper,
  .phone-number-column-wrapper {
    padding-top: 10px;
  }

  .send-notifications-wrapper {
    padding-left: 30px;
  }

  .send-notifications-wrapper > div {
    padding-bottom: 15px;
  }

  .update-mailing-clear-changes-wrapper a {
    min-width: 250px;
  }

  .batch-order-text-container {
    padding-left: 15px;
  }
}

/* END STEP ONE: RETURN ADDRESS */

/* START STEP TWO: MAILING ADDRESS */

.intl-address-inputs {
  display: none;
}

#pick-zip-code-enter {
  padding-right: 35px;
}

.pick-zip-code-cursor-icon {
  background: transparent url("./click-n-ship/images/search.svg") no-repeat;
  background-position: 97%;
  background-size: 21px;
  cursor: pointer;
  z-index: 18;
  height: 46%;
  position: absolute;
  top: 35px;
  right: 23px;
  width: 21px;
}

.error .pick-zip-code-cursor-icon {
  top: 29px;
}

.pick-zip-code-cursor-icon#dropOffLocationsButton {
  top: 53px;
}

.error .pick-zip-code-cursor-icon#dropOffLocationsButton {
  top: 48px;
}

.address-standardized-desc {
  padding-top: 25px;
}

.batch-order-container,
.parcel-select-container {
  margin-top: 20px;
}

.batch-add-button-container {
  padding-top: 30px;
  padding-bottom: 25px;
}

.batch-order-list {
  padding-top: 15px;
  /* display: none; */
}

.batch-order-list > ol {
  font-size: 14px;
  color: #595959;
}

.batch-order-list .inline-link-remove {
  padding-left: 4px;
}

.batch-order-list ol li div {
  color: #595959;
  font-weight: normal;
  font-weight: initial;
}

.batch-order-list li + li {
  padding-top: 10px;
}

.batch-remove-all {
  padding-bottom: 15px;
}

.batch-remove-all a.btn-primary {
  min-width: 200px;
}

.email-notification-wrapper {
  display: none;
}

.email-notification-option label,
.hold-for-pickup-option label,
.batch-order-option label {
  color: #333366;
  font-size: 14px;
}

.hold-pickup-content-wrapper {
  display: none;
  padding-left: 25px;
  padding-right: 25px;
}

.email-notification-option,
.hold-for-pickup-option,
.batch-order-option,
.parcel-select-option {
  padding-top: 15px;
}

.enter-any-additional-instructions textarea {
  border: 1px solid #333366;
  font-size: 14px;
}

.batch-order-label {
  font-size: 14px;
  color: #595959;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 30px;
}

.batch-order-container ol {
  list-style-type: none;
}

a#click-text-message-popover,
a#post-office-hours-popover,
a#standardized-address-popover-id {
  position: relative;
  top: 3px;
  left: 3px;
}

.sms-gateway-table {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
}

.sms-gateway-table p {
  font-size: 12px;
}

.sms-gateway-table .row {
  margin-right: 0px;
  margin-left: 0px;
  border-bottom: 1px solid #d8d8d8;
}

.sms-gateway-table .row:last-child {
  border-bottom: none;
}

.sms-gateway-table .col-5:first-child {
  border-right: 1px solid #d8d8d8;
}

.click-ship-address-link,
.click-ship-address-book-txt {
  padding-top: 30px;
}

.click-ship-mailing-address {
  font-size: 14px;
  color: #595959;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 30px;
}

.click-ship-address-box-results {
  color: #333366;
}

.click-ship-address-box-container,
.another-post-office-wrapper {
  padding-top: 15px;
}

.click-ship-address-box-wrapper {
  padding-top: 20px;
  /* display: none; */
}

.click-ship-individual-address {
  position: relative;
  display: block;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
  padding-bottom: 20px;
}

.individual-address-content-wrapper {
  padding-top: 25px;
  padding-left: 45px;
}

.click-ship-location {
  font-size: 18px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
  display: inline;
}

.click-priority-mail {
  display: inline;
  color: #333366;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.available-services {
  color: #333366;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.click-individual-address-detail {
  font-size: 14px;
  padding-top: 5px;
}

.post-office-hours {
  padding-top: 5px;
  color: #333366;
  font-size: 15px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

p.opening-closing-hours {
  padding: 0px;
}

input.pickup-address {
  margin-left: -23px !important;
  margin-right: 6px !important;
}

.notify-addressee-wrapper,
.notify-me-wrapper {
  /* display: none; */
}

.radio-container.notify-addressee-email-wrapper,
.radio-container.notify-addressee-text-message-wrapper,
.radio-container.notify-me-email-wrapper,
.radio-container.notify-me-text-message-wrapper {
  padding-top: 15px;
  padding-left: 20px;
}

.form-group.click-email-input,
.form-group.click-text-message-input {
  width: 50%;
  padding-top: 5px;
  padding-left: 20px;
  /* display: none; */
}

label.package-weight-label {
  padding-top: 20px;
}

/* Start Modal: Cancel & Remove Batch */

.modal-footer.cancel-batch-modal {
  text-align: center;
  border-top: none;
  padding: 6px 30px 30px 30px;
}

.btn-primary.button--white.batch-white-button {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  color: #333366;
  background: #ffffff;
  min-width: 100px;
  text-align: center;
  border: 2px #333366 solid;
  border-radius: 3px;
  height: 44px;
  text-decoration: none;
  padding: 10px 20px;
  line-height: 17px;
}

.btn-primary.batch-blue-button {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  color: #ffffff;
  background: #333366;
  min-width: 100px;
  text-align: center;
  border: 2px #333366 solid;
  border-radius: 3px;
  height: 44px;
  text-decoration: none;
  padding: 10px 20px;
  line-height: 17px;
}

.modal-content.cancel-batch-modal {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  float: left;
  width: 74%;
  height: auto;
}

#remove-selected .modal-buttons,
#cancel-batch .modal-buttons {
  text-align: center;
}

#remove-selected .button-container,
#cancel-batch .button-container {
  float: none;
}

.body-content.batch-address p {
  color: #3573b1;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 12px;
}

.body-content.batch-address p + p {
  padding-top: 0px;
}

/* End Modal: Cancel & Remove Batch */

.batch-order-list > ol > li {
  counter-increment: mobilebatchcounter;
}

.batch-order-list > ol > li:first-child {
  counter-reset: mobilebatchcounter;
}

.batch-order-list > ol > li:before {
  content: counter(mobilebatchcounter) " ";
  font-weight: normal;
  float: left;
  width: 5%;
}

@media only screen and (max-width: 767px) {
  .click-ship-address-box-results {
    display: none;
  }

  .batch-order-list ol {
    padding-left: 0px;
  }

  .batch-order-list li strong,
  .batch-order-list li span.item-divider {
    display: none;
  }

  .batch-order-list ol li div:first-child {
    color: #000000;
  }

  .batch-order-list a.inline-link-edit,
  .batch-order-list button.inline-link-edit {
    padding-right: 15px;
  }

  .batch-remove-all a.btn-primary {
    min-width: 250px;
  }

  .individual-address-content-wrapper {
    padding-left: 20px;
  }

  .available-services {
    padding-top: 10px;
  }

  .batch-add-button-container {
    text-align: center;
  }

  .batch-add-button-container a.btn-primary {
    min-width: 240px;
  }

  .hold-pickup-content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pick-zip-code-wrapper,
  #pick-zip-code-wrapper-id {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .batch-order-list ol {
    padding-left: 20px;
  }

  .batch-order-list ol li {
    padding-left: 20px;
  }
}

/* END STEP TWO: MAILING ADDRESS */

/* START STEP THREE: SHIPPING DATE */
.shipping-date-calendar {
  background: transparent url(./click-n-ship/images/calendar-icon.svg) no-repeat;
  background-position: right 10px top 9px;
  background-size: 24px 24px;
  cursor: pointer;
}

label.choose-shipping-date-label {
  padding-top: 30px;
}

.package-dimensions-input,
.non-standard-input {
  display: none;
  padding-left: 20px;
}
/* END STEP THREE: SHIPPING DATE*/

/* START STEP FOUR: PACKAGE DETAILS */
div.package-weight-input {
  padding-left: 20px;
}

.input-group-addon.package-weight-input {
  border-color: #333666;
  background-color: #ffffff;
  width: 20px;
  border-left: none;
  padding-right: 20px;
  vertical-align: middle;
}

form.form-inline.package-weight-input {
  display: grid;
}

.package-details-wrapper {
  padding-top: 20px;
}

.package-weight-info p {
  font-size: 14px;
  padding-top: 20px;
}

#shipping-type-error {
  padding-top: 25px;
}

.package-value {
  padding-top: 20px;
}

label.Package-details-header,
.package-dimensions-header,
.package-value-header {
  font-size: 14px;
  color: #595959;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 15px;
}

.package-weight-fields-wrapper .form-group {
  padding-top: 0px;
}

label.package-dimensions.checkbox-text.vertical {
  padding-top: 0px;
}

#package-dimensions-id {
  padding-left: 35px;
}

#nonmachinable-section {
  padding-top: 30px;
}

#shipping-gift-id {
  padding-left: 35px;
}

.package-dimensions,
.shipping-gift {
  padding-top: 20px;
  padding-left: 35px;
}

#dimensions-checkbox ~ strong,
#non-standard-package-checkbox ~ strong,
#gift-checkbox ~ strong {
  color: #333366;
  font-size: 14px;
}

.radio-container-package-details + .radio-container-package-details {
  padding-top: 20px;
}

span.package-details-radio-txt {
  color: #595959;
}

@media only screen and (max-width: 767px) {
  .package-dimensions,
  .shipping-gift {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) {
  #weight-modal .modal-dialog,
  #package-girth-modal .modal-dialog {
    max-width: 60%;
  }
}
/* END STEP FOUR: PACKAGE DETAILS */

/* START STEP FIVE: SERVICE TYPE */
.services,
.intl-option,
.service-type .mil-option,
.select-service-tabs .mil-option,
.step-five-domestic-continue,
.step-five-intl-continue,
.step-five-gxg-continue {
  display: none;
}

#service-type-dropdown-usa-id,
#service-type-dropdown-int-id {
  white-space: nowrap;
}

p.service-type-info {
  padding-top: 30px;
}

p.click-ship-post-office-link {
  padding-top: 10px;
}

.package-content-label {
  list-style: none;
  border-bottom: 2px solid #e71921;
}

.destination-address-content {
  padding: 20px;
}

@media (min-width: 768px) {
  #package-type-column-header {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .package-type-container-spacing {
    max-width: 38% !important;
  }

  .scheduled-delivery-container-spacing {
    max-width: 47% !important;
    flex: 1 0 25% !important;
  }

  .price-container-spacing {
    max-width: 14% !important;
  }
}

.package-type-service-label {
  color: #333366;
}

.up-down-container {
  display: inline-block;
}

.service-postal-code-label {
  color: #333366;
}

.label-close {
  display: inline-block;
}

.label-close:after {
  content: "";
  background-image: url(./click-n-ship/images/close_x.svg);
  padding-top: 20px;
  display: inline;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 5px 1px;
  position: absolute;
  cursor: pointer;
  left: 42px;
  margin-top: -13px;
  padding-left: 40px;
}

.up-down-container:after {
  background: url("./click-n-ship/images/blue-caret-down.svg") no-repeat 20px
    10px;
  content: "";
  background-size: 80%;
  background-position: 3px 4px;
  height: 20px;
  width: 28px;
  display: inline-block;
  transform: rotate(180deg);
  padding-top: 3px;
}

.scheduled-delivery-label {
  color: #333366;
}

.click-ship-destination-content {
  border-bottom: 1px solid #d8d8d8;
  list-style: none;
}

.package-del-price-content {
  padding: 15px;
}

.package-type-container {
  display: inline-block;
}

.package-type-title {
  display: block;
  padding-left: 20px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

.package-type-drop-off-location-header {
  color: #333366;
}

.package-type-desc-txt {
  padding-top: 0px;
  padding-left: 20px;
  font-size: 11px;
}

.scheduled-delivery-container {
  display: inline-block;
}

.scheduled-delivery-dates {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

.scheduled-delivery-option {
  padding-top: 10px;
}

.package-price-content {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

li.package-row,
.parcel-select-row {
  border-bottom: 1px solid #d8d8d8;
  list-style: none;
}

.parcel-select-row {
  padding-bottom: 15px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 15px;
}

#parcel-select-conditions-list {
  padding-bottom: 25px;
  padding-left: 50px;
  padding-top: 5px;
}

.row.selected-drop-off-location-wrapper {
  margin-left: 0px;
  margin-right: 0px;
}

#selected-drop-off-location {
  padding-bottom: 25px;
  padding-left: 25px;
  padding-top: 0px;
}

.selected-drop-off-location-local {
  padding-bottom: 5px;
  padding-left: 0px;
  padding-top: 5px;
}

#selected-drop-off-location::first-line {
  color: #333366;
}

#eligible-usps-connect-post-offices {
  padding-bottom: 25px;
}

#drop-off-location-fyi {
  font-size: 12px;
  padding-top: 0px;
}

.label-summary-data {
  padding-left: 60px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

.insurance-service-type {
  padding-left: 20px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

.insurance-container-restrictions-text {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.non-delivery-options-wrapper .radio-wrap {
  padding-left: 20px;
}

.click-ship-label-summary-wrapper {
  background: #e7e7e7;
  padding-bottom: 20px;
  margin-top: 30px;
}

.click-ship-label-summary-wrapper .horizontal-line-container {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25px;
}

li.label-summary-text {
  list-style-type: none;
}

.label-summary-top {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.label-summary-label {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.label-summary-content {
  list-style-type: none;
  padding-top: 15px;
}

.label-summary-wrapper {
  padding-top: 20px;
  padding-right: 20px;
}

.label-summary-total-content .label-summary-wrapper {
  padding-top: 10px;
}

.label-summary-price {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

hr.horizontal-line.label-summary-horizonal-line {
  border-top: 2px solid #333366;
}

.label-summary-total-content {
  list-style-type: none;
}

.label-summary-total {
  padding-left: 60px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

.step-five-privacy-act {
  font-size: 12px;
  padding-top: 10px;
}

.inline-link.label-privacy-policy {
  font-size: 12px;
}

.step-five-privacy-act-container {
  padding-top: 30px;
}

.prices-based-zip {
  padding-top: 15px;
}

.pme-drop-off-location {
  font-size: 18px;
  color: #333366;
  display: inline;
}

.pme-drop-off-address-detail {
  font-size: 14px;
  padding-top: 5px;
}

.pme-drop-off-service-commitment,
.pme-drop-off-by {
  color: #333366;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.pme-drop-off-service-commitment-info,
.pme-drop-off-by-info {
  display: inline;
  color: #333366;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.select-service-wrapper {
  padding-top: 20px;
}

.select-service-text {
  font-size: 14px;
  color: #333366;
  padding-right: 20px;
}

.select-service-tabs .active {
  border-bottom: 4px solid #e71921;
  padding-bottom: 1px;
  pointer-events: none;
}

ul.select-service-tabs li {
  display: inline-block;
}

ul.select-service-tabs li + li {
  margin-left: 20px;
}

.select-service-tabs {
  display: inline-block;
  color: #333366;
  text-align: center;
  font-size: 14px;
  margin-bottom: 4px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

ul.select-service-tabs {
  text-align: right;
  padding-top: 13px;
}

.international-tabs {
  display: none;
}

.view-as {
  color: #333366;
  font-size: 14px;
  text-align: right;
  padding-top: 15px;
}

.view-as-list,
.view-as-grid {
  color: #333366;
  font-size: 14px;
  padding-left: 10px;
  opacity: 0.6;
}

.view-as-list.active,
.view-as-grid.active {
  opacity: 1;
}

.view-as-list button:after {
  content: "";
  background: transparent url(./click-n-ship/images/list-icon.svg) no-repeat;
  display: inline-block;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: -5px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  height: 21px;
  width: 21px;
  background-size: 21px 21px;
}

.view-as-grid button:after {
  content: "";
  background: transparent url(./click-n-ship/images/grid-view-icon.svg)
    no-repeat;
  display: inline-block;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: -5px;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  height: 21px;
  width: 21px;
  background-size: 21px 21px;
}

ul.select-service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.select-service-grid li.package-row {
  width: 32%;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 3px;
}

.package-schedule-price-wrapper a.info-icon {
  display: none;
}

.package-schedule-price-wrapper.select-service-grid a.info-icon {
  display: inline-block;
}

.info-icon-without-popover {
  height: 40px;
  margin-bottom: -10px;
  margin-left: -10px;
  margin-top: -10px;
  width: 40px;
}

#services-empty-table {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.scheduled-delivery-container,
#scheduled-delivery-container-list-id,
.price-container {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.price-container {
  text-align: right;
}

.package-row-img {
  display: none;
}

.package-type-container,
.select-service-grid .package-row-text,
.select-service-grid .package-row-img {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.select-service-grid .package-row-text {
  padding-right: 0px;
}

.scheduled-delivery-time {
  display: none;
}

.select-service-grid .scheduled-delivery-time {
  display: inline;
}

.select-service-grid .package-type-container,
.select-service-grid .scheduled-delivery-container,
.select-service-grid .price-container {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.select-service-grid .price-container {
  text-align: left;
}

.select-service-grid .package-row-img {
  display: block;
  text-align: right;
}

.select-service-grid .package-row-img img {
  max-width: 65%;
}

.select-service-grid li.package-row .package-type-desc-txt {
  padding-left: 0px;
  font-size: 11px;
  padding-top: 5px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.select-service-grid li.package-row .package-type-title {
  padding-left: 0px;
  font-size: 12px;
  line-height: 16px;
}

.select-service-grid li.package-row .package-price-content {
  font-size: 20px;
  padding-top: 5px;
}

.select-service-grid li.package-row .scheduled-delivery-dates {
  font-size: 11px;
  color: #595959;
}

.select-service-grid li.package-row .scheduled-delivery-option {
  display: none;
}

.package-schedule-price-wrapper {
  padding-top: 10px;
}

.package-schedule-price-wrapper li.package-row-selected,
.parcel-select-row-selected {
  background-color: #f7f7f7;
}

li.row-selected {
  background-color: #f7f7f7;
}

.select-service-grid li.package-row-selected {
  border-bottom: 4px solid #e71921;
  padding-bottom: 0px;
}

.step-five-domestic-continue .button-wrapper .button-container a {
  min-width: 275px;
}

label.insurance-express-check,
label.signature-services-check {
  padding-top: 0px;
  margin-left: -10px;
}

.click-ship-prio-int-destination-content {
  height: 60px;
  list-style: none;
  border-bottom: 1px solid #d8d8d8;
}

.click-ship-prio-int-destination-content:last-child,
.click-ship-destination-content:last-child {
  border-bottom: none;
}

.prio-int-destinaton-city-location {
  display: inline;
  padding-left: 20px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

@media only screen and (max-width: 767px) {
  .select-service-text,
  ul.select-service-tabs {
    display: none !important;
  }

  .scheduled-delivery-container,
  #scheduled-delivery-container-list-id {
    padding-left: 36px;
  }

  .scheduled-delivery-option {
    padding-top: 0px;
    font-size: 12px;
  }

  .scheduled-delivery-dates {
    padding-top: 0px;
    font-size: 12px;
  }

  .package-type-desc-txt {
    padding-top: 0px;
    font-size: 12px;
  }

  .package-type-title,
  .package-price-content {
    font-size: 12px;
  }

  .label-summary-data {
    padding-left: 20px;
  }

  .click-ship-destination-content input[type="checkbox"] ~ span.checkbox {
    vertical-align: top;
  }

  .select-service-grid li.package-row {
    width: 100%;
  }

  .package-type-container,
  .scheduled-delivery-container,
  #scheduled-delivery-container-list-id {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .price-container {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .select-service-grid .scheduled-delivery-container {
    order: 0;
    padding-left: 15px;
  }

  .click-ship-label-summary-wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }

  .select-service-grid .package-row-img img {
    width: 105px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .select-service-grid li.package-row {
    width: 49%;
  }

  .select-service-grid .package-row-img img {
    max-width: 55%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 827px) {
  .select-service-tabs {
    font-size: 11.3px;
  }

  ul.select-service-tabs li + li {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 828px) and (max-width: 959px) {
  .select-service-tabs {
    font-size: 12px;
  }

  ul.select-service-tabs li + li {
    margin-left: 15px;
  }
}

.step5TextBlack {
  color: black;
}

/* END STEP FIVE: SERVICE TYPE */

/* START STEP 6: PARTICIPATING POST OFFICE LOCATION */
.drop-shipment-wrapper {
  padding-top: 20px;
  font-size: 14px;
}

label.checkbox-text.other-post-office {
  padding-top: 30px;
}

.click-ship-address-box-wrapper input {
  margin-left: -23px !important;
}

.failure-to-follow-p {
  padding-top: 30px;
  font-size: 14px;
}
/* END STEP 6: PARTICIPATING POST OFFICE LOCATION */

/* START STEP 7: DESTINATION POSTAL CODE */
.click-ship-destination-wrapper {
  padding-top: 15px;
}

.click-ship-destination-content-label {
  list-style: none;
  border-bottom: 2px solid #e71921;
  padding-left: 0px;
}

.destinaton-city-location-label,
.destination-postal-code-label {
  color: #333366;
}

p.destinaton-city-location {
  padding-left: 20px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
}

p.destination-postal-code:not(#extraFreeInsurance) {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  color: #333366;
  padding-top: 0px;
}

label.checkbox-text.update-address-label {
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .Step_Seven_Container .button-container a.btn-primary {
    min-width: 250px;
  }
}

/* END STEP 7: DESTINATION POSTAL CODE */

/* START STEP 8: CUSTOMS INFORMATION */
.prohibitions-wrapper a {
  font-size: 12px;
}

.step-8-blank-table p {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.package-items-table {
  counter-reset: table-counter;
}

.table .step-8-full-table {
  border-top: none;
  display: table-row-group;
}

.package-items-table tr th[scope="row"]:before {
  counter-increment: table-counter;
  content: counter(table-counter);
}

.package-items-table th,
.package-items-table td {
  text-align: right;
  font-size: 14px;
  color: #333366;
}

.package-items-table th[scope="row"],
.package-items-table td {
  border: none;
}

.package-items-table th[scope="col"] {
  border-bottom-color: #e71921;
  border-width: 1px;
  font-size: 14px;
  color: #333366;
  border-top: none;
  border-bottom-width: 2px;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.package-items-table .country-td {
  text-align: left;
  padding-left: 20px;
}

.package-items-table th:last-child,
.package-items-table td:last-child {
  padding-right: 20px;
}

@media only screen and (max-width: 767px) {
  .package-items-table .inline-link-edit span,
  .package-items-table .inline-link-remove span {
    display: none;
  }

  .package-items-table tbody tr {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    flex-wrap: wrap;
  }

  .package-items-table tbody tr:last-child {
    border-bottom: none;
  }

  .package-items-table tbody tr th,
  .package-items-table tbody tr td {
    display: block;
  }

  .package-items-table tbody tr th {
    order: 1;
    width: 10%;
  }

  .package-items-table .package-items-desc,
  .package-items-table .package-items-desc-mobile {
    font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    order: 2;
    width: 60%;
    text-align: left;
  }

  .package-items-table .package-items-desc-mobile .package-items-desc:after {
    background-image: url("./click-n-ship/images/blue-caret-down.svg");
    content: "";
    display: inline-block;
    background-repeat: no-repeat;
    height: 13px;
    width: 13px;
    background-size: 13px 13px;
    left: 5px;
    top: 3px;
    position: relative;
  }

  .package-items-table .package-items-hs {
    display: none;
    order: 8;
    width: 100%;
    text-align: left;
    padding-left: 10%;
  }

  .package-items-table .country-td {
    display: none;
    order: 9;
    width: 100%;
    text-align: left;
    padding-left: 10%;
  }

  .package-items-table .package-items-qty {
    order: 5;
    width: 33.333333%;
    text-align: left;
    padding-left: 10%;
  }

  .package-items-table .package-items-value {
    order: 6;
    width: 33.333333%;
    text-align: center;
  }

  .package-items-weight {
    order: 7;
    width: 33.333333%;
  }

  .package-items-edit {
    order: 3;
    width: 15%;
  }

  .package-items-remove {
    order: 4;
    width: 15%;
  }

  .package-items-table .package-items-desc,
  .package-items-table .package-items-desc-mobile,
  .package-items-table .package-items-edit,
  .package-items-table .package-items-remove,
  .package-items-table .package-items-qty,
  .package-items-table .package-items-value,
  .package-items-table .package-items-weight,
  .package-items-table .package-items-hs,
  .package-items-table .country-td {
    border-top-style: none;
    border-top-width: 0px;
  }

  .package-items-table tbody,
  .package-items-tr-active .package-items-hs,
  .package-items-tr-active .country-td {
    display: block;
  }

  .package-items-tr-active
    .package-items-desc-mobile
    .package-items-desc:after {
    transform: rotate(180deg);
  }
}

strong.secondary-bold {
  color: #3573b1;
}

.step-eight-header {
  padding-top: 30px;
}

.census-bureau-customs-header {
  padding-top: 40px;
}

.customs-info-label {
  font-size: 14px;
  color: #595959;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 30px;
  padding-bottom: 20px;
}

.radio-wrap.customs-info-radio-wrap {
  padding-top: 0px;
}

.customs-info-radio-label {
  padding-top: 30px;
  font-size: 14px;
}

.commercial-senders-info-box {
  background: #f7f7f7;
  padding: 15px;
  margin-top: 26px;
}

.commercial-senders-info-box.hs-tariff-required {
  background: none;
}

@media (min-width: 992px) {
  .commercial-senders-info-box.hs-tariff-required {
    padding-top: 0px;
    margin-top: 0px;
  }
}

.commercial-sender-note {
  padding-top: 10px;
  padding-left: 25px;
}

.commercial-sender-cb-wrapper {
  padding-top: 20px;
}

.form-group.oz-weight-box {
  padding-top: 50px;
}

.form-group.total-weight-box p {
  padding-top: 35px;
  font-size: 22px;
  color: #333366;
}

.add-new-item-wrapper {
  display: inline-block;
  padding-top: 30px;
}

.add-new-item-plus {
  cursor: pointer;
  display: inline-block;
}

.add-new-item-plus:after {
  content: " +";
  color: #e71921;
  position: relative;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 20px;
}

.package-items-red-line {
  border-top: 2px solid #e71921;
}

.package-items-content-header {
  padding-bottom: 10px;
  padding-top: 20px;
  font-size: 14px;
  width: 17%;
}

.inline-link-edit,
.inline-link-remove,
.inline-link-delete {
  color: #333366;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
}

a.inline-link-edit:after,
button.inline-link-edit:after {
  content: "";
  display: inline-block;
  background-image: url(./click-n-ship/images/edit-icon.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 21px;
  background-size: 14px 14px;
  left: 5px;
  top: 1px;
  position: relative;
}

a.inline-link-remove:after,
button.inline-link-remove:after {
  content: "";
  display: inline-block;
  background-image: url(./click-n-ship/images/close_x.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 21px;
  background-size: 14px 14px;
  left: 5px;
  top: 3px;
  position: relative;
}

.edit-number {
  width: 5%;
  padding-bottom: 0px;
  font-size: 14px;
  padding-top: 10px;
}

.total-package-weight-box > div {
  background: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
}

p.total-package-weight-text {
  color: #333366;
  font-size: 16px;
}

p.calc-package-weight-text {
  color: #333366;
  font-size: 20px;
}

.total-package-weight-box .inline-link-remove {
  font-size: 14px;
}

.error p.total-package-weight-text {
  color: #e71921;
}

.us-census-bureau-text {
  padding-top: 30px;
  padding-bottom: 10px;
}

.restrictions-prohibitions-text {
  padding-top: 15px;
}

.step-eight-restrictions {
  padding-top: 30px;
  font-size: 12px;
}

.step-eight-prohibitions {
  font-size: 12px;
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  #step8-add-item,
  #step8-update-item,
  #step8-cancel-item {
    min-width: 250px;
  }

  .package-items-red-line {
    border-top: none;
    display: flex;
    flex-wrap: wrap;
  }

  .edit-number {
    width: 10%;
    flex: auto;
  }

  .total-item-weight-responsive {
    float: right;
  }

  .mobile-package-total h2 {
    padding-top: 15px;
    padding-left: 20px;
  }

  #commercial-sender-section-step-7,
  #commercial-sender-section-step-8,
  .commercial-sender-section-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-package-value p,
  .mobile-package-weight p {
    text-align: right;
    color: #333366;
    font-size: 14px;
    padding-top: 0px;
  }

  .Step_Eight_Container .button-container a.btn-primary,
  #submit-hs-tariff-info-button,
  #tlc-hs-tariff-modal-close-button,
  #tlc-hs-tariff-modal-continue-button,
  #tlc-prohibited-close-button,
  #tlc-prohibited-continue-button,
  #tlc-landed-cost-button,
  #submit-tlc-email-button {
    min-width: 250px;
  }

  p.total-package-weight-text {
    font-size: 16px;
  }

  .total-package-weight-box .inline-link-remove strong {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .item-value-quantity-wrapper {
    display: block;
  }

  .prohibitions-wrapper {
    padding-top: 15px;
  }

  .total-package-weight-box {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
  }

  #step8-remove-confirmation-modal .modal-dialog,
  #step8-remove-all-confirmation-modal .modal-dialog {
    max-width: 410px;
  }

  #step8-remove-confirmation-modal .modal-buttons,
  #step8-remove-all-confirmation-modal .modal-buttons {
    text-align: center;
  }
}

/* 	VERTICAL TABS COMPONENT */
.v-tab-content div {
  display: none;
}

.v-tab-content {
  color: #595959;
}

.v-tab h3 {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #333366;
  padding-top: 13px;
  padding-bottom: 13px;
}

.v-tab div:hover a h3,
.v-tab div:hover a p {
  color: #333366;
}

.v-tab a,
.v-tab button {
  text-decoration: none;
  position: relative;
}

.v-tab a:focus h3,
.v-tab button:focus h3 {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.v-tab-content ul,
.v-tab-content p {
  font-size: 12px;
}

.v-tab div:first-of-type {
  border-top: 1px solid #d9d9d9;
}

.v-tab div {
  border-bottom: 1px solid #d9d9d9;
}

.v-tab-content div.active,
.v-tab div.active {
  display: block;
}

.vdrawers,
#restrictions-and-prohibitions {
  padding: 0 0 30px 0;
}

.vdrawers h2 {
  padding-top: 50px;
  padding-bottom: 30px;
  margin: 0;
  font-size: 14px;
  color: #595959;
}

.v-tab div.active:not(.mobile-active):not(.mobile-inactive) h3:after {
  background-image: url(./click-n-ship/images/white-arrow.svg);
}

.vdrawers li {
  font-size: 12px;
}

ol.requirement-exceptions-list {
  padding-left: 30px;
  padding-top: 5px;
  list-style: decimal;
}

.exceptions-header,
.requirement-exceptions-header,
.exceptions-list {
  padding-top: 5px;
}

ul.restrictions-list li,
ul.exceptions-list li {
  margin-left: 15px;
}

@media only screen and (min-width: 768px) {
  .v-tab div.active,
  .v-tab div.active:hover {
    background-color: #333366;
  }

  .v-tab div.active a h3,
  .v-tab div.active a p,
  .v-tab div.active:hover a h3,
  .v-tab div.active:hover a p,
  .v-tab div.active button h3,
  .v-tab div.active button p,
  .v-tab div.active:hover button h3,
  .v-tab div.active:hover button p {
    color: #ffffff;
  }

  .v-tab div:hover {
    background-color: #eeeeee;
  }

  .v-tab div h3:after {
    content: "";
    height: 25px;
    width: 20px;
    background-image: url(./click-n-ship/images/blue-caret.svg);
    background-repeat: no-repeat;
    display: inline-block;
    float: right;
    padding-right: 25px;
  }

  .v-tab div.shipping-restrictions.active h3:after {
    background-image: url(./click-n-ship/images/white-arrow.svg);
  }

  .v-tab div {
    position: relative;
    padding-right: 0px;
    padding-left: 0px;
  }

  .v-tab h3 {
    padding-left: 15px;
  }

  .v-tab-content {
    padding-left: 20px;
  }

  ol.requirement-exceptions-list li {
    font-size: 12px;
  }

  .mobile-content {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .v-tab div.mobile-active a h3,
  .v-tab div.mobile-active a p,
  .v-tab div.mobile-active:hover a h3,
  .v-tab div.mobile-active:hover a p,
  .v-tab div.mobile-active button h3,
  .v-tab div.mobile-active button p,
  .v-tab div.mobile-active:hover button h3,
  .v-tab div.mobile-active:hover button p {
    color: #333366;
  }

  .v-tab div.mobile-active,
  .v-tab div.mobile-active:hover {
    background-color: #f7f7f7;
  }

  .v-tab div h3 {
    width: 100%;
    position: relative;
  }

  .v-tab div a h3:after,
  .v-tab div button h3:after {
    content: "";
    height: 25px;
    width: 25px;
    background-image: url(./click-n-ship/images/blue-caret.svg);
    background-repeat: no-repeat;
    display: inline-block;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: transform 0.2s ease-out;
    float: right;
    margin-top: 8px;
  }

  .v-tab div.mobile-active a h3:after,
  .v-tab div.mobile-active button h3:after {
    background-image: url(./click-n-ship/images/blue-caret.svg);
    -ms-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    transition: transform 0.2s ease-out;
    margin-top: -5px;
  }

  .v-tab div {
    padding-right: 15px;
    padding-left: 15px;
  }

  .mobile-content p {
    font-size: 12px;
  }

  .v-tab div .mobile-content {
    padding-left: 0;
    padding-right: 0;
    border-top: none;
    border-bottom: none;
  }

  .v-tab {
    padding-left: 0px;
    padding-right: 0px;
  }

  .v-tab p {
    padding-bottom: 10px;
  }

  #no-padding-mobile-restrictions,
  #no-padding-mobile-restrictions-gxg {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#buyer-info-first-middle-names {
  margin: 0px;
}
/* END FOR STEP 8: CUSTOMS INFORMATION */

/* START STEP 9: ADDITIONAL GXG INFORMATION */
.step-nine-header {
  padding-top: 20px;
}

.item-one-label {
  color: black;
  font-size: 14px;
}

.total-declared-value-wrapper {
  display: none;
}

.commercial-shipment-cb-wrapper {
  padding-top: 20px;
}

.shipping-purpose-wrapper {
  padding-top: 5px;
}

.recipients-info-wrapper {
  padding-top: 30px;
}

.recipients-info-wrapper p {
  color: black;
}

.recipients-info-wrapper p + p {
  padding-top: 0px;
}

.diff-buyer-info-input {
  display: none;
  padding-top: 20px;
  padding-left: 25px;
  padding-bottom: 20px;
}

.commercial-shipment-input {
  display: none;
  padding-left: 25px;
}

.invoice-date-calendar {
  background: transparent url(./click-n-ship/images/calendar-icon.svg) no-repeat;
  background-position: right 10px top 9px;
  background-size: 24px 24px;
  cursor: pointer;
}

.step9-buttons .btn-primary {
  min-width: 250px;
}
/* END STEP 9: ADDITIONAL GXG INFORMATION */

/*START STEP 10: GLOBAL EXPRESS*/

.gray-box.global-express-form {
  padding-top: 30px;
  padding-bottom: 60px;
}

label.global-express-details {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  padding-top: 30px;
}

tr.global-express-mailer-header th {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  color: #595959;
  text-align: left;
}

p.advertisment,
th.service-stype-header {
  padding-left: 0px;
}

.global-express-form-container {
  padding-top: 30px;
}

.global-express-service-type-price-container td {
  padding-top: 25px;
  padding-left: 15px;
}

.service-type-item p + p,
.service-price p + p {
  padding-top: 0px;
}

.service-type-item p {
  color: #000000;
}

.delivery-date p {
  padding-top: 10px;
  color: #000000;
}

td.final-post-office-type,
td.final-post-office-price {
  padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

td.final-post-office-price,
td.final-post-office-service-price {
  float: right;
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

td.final-post-office-total-price {
  float: right;
  font-size: 14px;
  color: #333366;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

tr.total-price-container {
  color: #333366;
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: left;
}

tr.post-office-price-header-wrapper {
  font-size: 14px;
  color: #333666;
  border-bottom: 2px solid #e71921;
}

td.final-post-office-service-type {
  padding-left: 15px;
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

th.service-type-header {
  text-align: left;
  padding-left: 15px;
}

tr.global-express-mailer-header,
tr.global-express-duties-Taxes-info {
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

tr.global-express-service-mailer-container {
  font-size: 14px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 13px;
}

.mailer-address-header,
.ship-header,
.package-information-header {
  color: #595959 !important;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
}

p.holidays {
  padding-top: 30px;
}

.global-express-service-mailer-container p + p {
  padding-top: 0px;
}

tr.global-express-service-type-price-container {
  font-size: 12px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

th.post-office-price-header {
  float: right;
  padding-right: 15px;
}

td.total-txt {
  padding-left: 15px;
}

td.service-price {
  float: right;
  padding-right: 15px;
}

td.usps-total-price-service-type {
  float: right;
  padding-right: 15px;
}

.service-type-duties-tax p,
.global-express-guaranted-address p {
  padding-top: 10px;
  color: #000000;
}

td.service-price p {
  text-align: right;
}

tr.global-express-service-type-price-container {
  border-bottom: 2px solid #333366;
}

.global-express-service-mailer-container p,
p.holidays-day {
  color: #000000;
}

p.holidays-day + p.holidays-day {
  padding-top: 0px;
}

.service-type-item,
p.service-type {
  color: #000000;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
}

.service-price p {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  color: #000000;
  padding-left: 18px;
}

label.global-express-details.final-price {
  padding-bottom: 10px;
}

.step10-buttons .btn-primary {
  min-width: 250px;
}

.step10-buttons {
  padding-top: 15px;
}

.global-express-form-content .button-wrapper {
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .ship-header,
  .package-information-header {
    padding-top: 30px;
  }

  .service-type-duties-tax p {
    padding-left: 0px;
  }

  .button-container.go-cart-button {
    padding-left: 0px;
  }

  .gray-box.global-express-form {
    padding-top: 15px;
  }
}
/* END STEP 10: GLOBAL EXPRESS */

/* START PREFERENCES */
.preferences-greeting {
  padding-top: 20px;
}

.click-ship-pref-package-type {
  padding-top: 10px;
}

.click-ship-pref-package-type p {
  font-size: 14px;
}

.click-ship-pref-package-type li.package-row-selected {
  background-color: #f7f7f7;
}

.click-ship-pref-package-type .package-type-title {
  display: inline-block;
}

.ser-perf-edit-appent-wraper label {
  color: #333366 !important;
}

.horizontal-line-container.separator {
  padding-top: 10px;
  margin-top: 0px;
  right: 72px;
}

.horizontal-line.separator {
  border-top: 1px solid #ededed;
  margin-bottom: 0px;
}

.radio-btn-list {
  padding-top: 12px;
  list-style-type: none;
}

#SCAN-form-alert {
  padding-top: 40px;
}

.checkbox-padding-left {
  padding-left: 30px;
}

.horizontal-line-container.package-type {
  margin-top: -23px;
}

.ret-ad-edit .edit-link,
.ret-ad-edit .delete-link {
  display: inline-block;
}

.ret-ad-edit span {
  font-size: 16px;
}

#shipment-notifications-checkbox-wrapper {
  padding-left: 40px;
}

#hide-postage-price-mail-class-inputs-wrapper,
#hide-postage-price-address-inputs-wrapper {
  padding-left: 50px;
  padding-top: 10px;
}

#hide-postage-price-address-inputs-table {
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  scrollbar-width: auto;
  scrollbar-color: #979797 #d8d8d8;
}

#hide-postage-price-address-inputs-table::-webkit-scrollbar {
  width: 10px;
}

#hide-postage-price-address-inputs-table::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  border-radius: 10px;
}

#hide-postage-price-address-inputs-table::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 10px;
}

#hide-postage-price-address-saved {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
  padding-left: 3px;
  scrollbar-width: auto;
  scrollbar-color: #979797 #d8d8d8;
}

#hide-postage-price-address-saved::-webkit-scrollbar {
  width: 10px;
}

#hide-postage-price-address-saved::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  border-radius: 10px;
}

#hide-postage-price-address-saved::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 10px;
}

#hide-postage-price-display-wrapper {
  padding-left: 15px;
}

#hide-postage-price-address-display-wrapper {
  padding-left: 15px;
}

.checkbox-wrap#select-updates-container {
  padding-top: 0px;
}

.Package_Options_Container .button-wrapper,
.Hide_Postage_Price_Container .button-wrapper,
.Shipment_Notifications_Container .button-wrapper,
.Notify_Recipient_Shipping_Container .button-wrapper,
.Print_Settings_Container .button-wrapper,
.Reference_Number_Container .button-wrapper,
.Return_Address_Container .button-wrapper {
  padding-bottom: 0px;
}

span#click-ship-message-counter {
  /* width: 100%; */
  display: block;
}

.return-address-edit-append .sub-header {
  padding-top: 20px;
}

/* Start Package Options Preference */
.edit-link,
.delete-link {
  padding-top: 5px;
  font-size: 16px;
}

.delete-link {
  padding-top: 0px;
}

.service-preferences-wrapper {
  padding-top: 25px;
}

.ser-perf-edit-appent-wraper .btn-primary,
.shipment-notifications-editing-wrapper .btn-primary {
  min-width: 120px;
}

.refrence-number-edit-btns .btn-primary,
.print-preferences-edit-btns .btn-primary,
.notify-recipient-step-edit-feedback .btn-primary {
  min-width: 120px;
}

.return-address-edit-btns .btn-primary {
  min-width: 120px;
}

.shipping-zip-code-btns .btn-primary {
  min-width: 120px;
}

@media only screen and (max-width: 767px) {
  .ser-perf-edit-appent-wraper .radio-container label,
  .print-preferences-edit label {
    max-width: 90%;
  }

  .click-ship-pref-package-type .package-del-price-content {
    padding-left: 0px;
  }

  .click-ship-pref-package-type .package-type-title {
    max-width: 93%;
  }

  .ser-perf-edit-appent-wraper .button-container .btn-primary,
  .shipment-notifications-editing-wrapper .btn-primary,
  .notify-recipient-step-edit-feedback .btn-primary,
  .print-preferences-edit-btns .btn-primary,
  .refrence-number-edit-btns .btn-primary,
  .return-address-edit-btns .btn-primary,
  .shipping-zip-code-btns .btn-primary,
  .shipping-zip-code-btns .btn-primary {
    min-width: 240px;
  }
}
/* End Package Options Preference */

/* Start Shipment Notifications Preference */
.contact-notifications {
  padding-top: 25px;
}

.notifications-options-line {
  padding-top: 20px;
}

.notifications-options-line p {
  padding-top: 0px;
}

/* End Shipment Notifications Preference */

/* Start Notify Recipient Preference */
.set-message-line {
  padding-top: 25px;
}

.shipping-message-wrapper {
  padding-top: 25px;
}

.shipping-message {
  padding-top: 25px;
}

.default-message {
  padding-top: 25px;
}

#count_message {
  background-color: #ffffff;
  margin-top: -23px;
  margin-right: 0px;
  color: #333366;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-weight: normal;
  font-size: 13px;
  padding: 0px;
  margin-left: 15px;
}

.refrence-number-edit-append,
.print-preferences-edit,
.notify-recipient-step-edit-feedback {
  display: none;
  width: 100%;
}
/* End Notify Recipient Preference */

/* Start Print Setting Preference */
.print-settings-line {
  padding-top: 25px;
}

.print-settings-step.print-reference-line {
  padding-top: 25px;
}
/* End Print Settings Preference */

/* Start Reference Number Preference */
.reference-number-line-characters {
  padding-top: 25px;
}

.reference-number-line {
  padding-top: 25px;
}
/* End Reference Number Preference */

/* Start Return Address Preference */
.return-adddress-line-label {
  padding-top: 25px;
}

.return-adddress-step.return-adddress-line {
  padding-top: 25px;
}

.return-address-fields {
  margin-left: -15px;
}

.return-address-edit-append {
  display: none;
}
/* End Return Address Preference */

/* Start Shipping From ZIP Code Preference */
.shipping-zip-code-set-alternative {
  padding-top: 25px;
}

.shipping-zip-code-line {
  padding-top: 25px;
}

.shipping-zip-code-edit-append {
  display: none;
  width: 100%;
}

/* End Shipping From ZIP Code Preference */

.return-address-step.user-return-address span {
  padding-top: 0px;
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0px;
}

.ser-perf-info {
  padding-left: 15px;
  padding-top: 20px;
  font-size: 12px;
}

.ser-perf-edit-appent-wraper table {
  margin-top: 30px;
  width: 50%;
}

.ser-perf-edit-appent-wraper table tr .radio-container {
  margin-top: 15px;
}

.ser-perf-edit-appent-wraper table th:first-child {
  border-bottom: 2px solid #e71921;
}

.ser-perf-edit-appent-wraper table tr th p {
  margin: 20px;
  float: left;
}

.ser-perf-edit-appent-wraper table tr td p {
  margin: 0px 20px;
  font-size: 14px;
}

.ser-perf-edit-appent-wraper table tr td:hover {
  background-color: #ededed;
  border-bottom: 1px solid #8d8d8d;
}

.ser-perf-edit-appent-wraper table tr td .radio-button:checked:after {
  display: block;
}

.ser-perf-edit-appent-wraper table tr td {
  border-bottom: 1px solid #8d8d8d;
}

.ser-perf-edit-appent-wraper .checkbox-container {
  padding: 30px 0px 5px 20px;
}

p.ser-perf-clear-sel {
  padding: 30px 0px 0px 0px;
  text-decoration: underline;
  color: #204ac8;
}

p.ser-perf-clear-sel:hover {
  color: #4169e1;
  text-decoration: unset;
}

.rectangular-box-girth {
  padding-left: 20px;
}

.package-dimension-not-standard {
  padding-left: 20px;
}

.ser-perf-package-weight {
  padding: 0px 0px 0px 20px;
}

#select-updates-container {
  padding-left: 35px;
}

.ser-pref-updates,
.ship-notifications-updates,
.notify-recip-updates,
.shipment-notifications-editing-wrapper {
  display: none;
}

.ship-notifications-updates > div + div {
  padding-top: 26px;
}

.ship-notifications-updates span {
  font-size: 14px;
  color: #595959;
}

.ship-notifications-updates p {
  color: #000000;
}

.background {
  position: absolute;
  z-index: 0;
  background: white;
  display: block;
  min-height: 50%;
  min-width: 50%;
}

.content {
  position: absolute;
  z-index: 1;
}

.notify-recip-updates {
  margin-top: 30px;
}

.print-settings-updates,
.shipping-zip-code-updates,
.ref-num-edit-updates {
  display: none;
}

@media only screen and (max-width: 767px) {
  .ser-perf-edit-appent-wraper table {
    margin-top: 30px;
    width: 100%;
  }

  .click-ship-pref-package-type .package-row {
    height: 100px;
  }
}

/* END PREFERENCES */

/* START SHIPPING HISTORY */

.Shipping_History_Container .gray-box-wrapper .form-group {
  padding-top: 15px;
}

li.dropdown-disabled a,
li.dropdown-disabled a:visited,
li.dropdown-disabled a:active,
li.dropdown-disabled a:hover {
  background-color: #ffffff !important;
  color: #595959 !important;
}

.name-company-input {
  display: none;
}

input#shipping-history-cal {
  padding-right: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.advanced-search-link {
  padding-top: 20px;
}

.advanced-search-link.enhanced-experience-styling {
  padding-top: 10px;
}

.enhanced-experience-text {
  color: black !important;
  padding-top: 10px !important;
  font-size: 14px !important;
}

.advanced-search-link button:after {
  content: "";
  display: inline-block;
  position: relative;
  right: -5px;
  background-size: 22px 22px;
  top: 8px;
  width: 22px;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 25px;
  background-image: url("./click-n-ship/images/blue-caret-down.svg");
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 0.2s ease-out;
}

.advanced-search-link button.active:after {
  background-image: url("./click-n-ship/images/blue-caret-down.svg");
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: transform 0.2s ease-out;
  top: 5px;
}

.advanced-search-options {
  display: none;
  padding-top: 20px;
}

.advanced-search-options label.checkbox-component {
  font-size: 14px;
  padding-top: 5px;
  position: relative;
  margin-bottom: 0px;
  display: table;
}

.advanced-search-options .radio-container.horizontal {
  padding-top: 50px;
}

.adv-remove-link {
  display: inline-block;
  vertical-align: top;
  margin-top: 50px;
  padding-left: 20px;
  font-size: 16px;
  cursor: pointer;
}

.adv-remove-link:after {
  content: "";
  background-image: url(./click-n-ship/images/close_x.svg);
  display: inline;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  padding-left: 26px;
  background-position: 9px 4px;
}

.adv-add-column-link {
  padding-top: 20px;
}

.adv-add-column-link button:after {
  content: "";
  display: inline-block;
  background-image: url(./click-n-ship/images/red-plus-icon.svg);
  background-repeat: no-repeat;
  height: 15px;
  width: 16px;
  background-size: 12px 18px;
  position: relative;
  background-position: 0px 0px;
  top: 1px;
  right: -3px;
}

.sort-by-handle {
  margin-top: 53px;
  margin-left: 10px;
  height: 15px;
}

.sort-by-placeholder {
  height: 1px;
  background: #333366;
}

.adv-sort-labels .adv-sort-button,
.adv-sort-labels .adv-clear-button {
  min-width: 120px;
}

.gray-box .gray-box-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0px;
  margin-top: 20px;
}

.adv-manage-table-columns .checkboxes.container {
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 160px;
}

.adv-manage-table-columns .checkbox-container {
  padding-top: 15px;
}

.adv-manage-table-columns .checkbox-wrap {
  padding-top: 0px;
  width: 33.333%;
}

table.shipping-history tr {
  border-top: 1px solid #999999;
}

.shipping-history tr.label-error {
  border-top: none;
}

select.search-by {
  background-color: #ffffff;
}

.shipping-history-search-btn {
  margin-top: 22px;
  min-width: auto;
}

.track-proceed .btn-primary {
  margin-top: 0px;
  min-width: 110px;
}

.track-proceed-botton {
  float: right;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  margin-left: 20px;
  display: inline;
}

.labels-selected-container {
  display: none;
  margin-top: 20px;
  padding-bottom: 22px;
  background: #f7f7f7;
}

.labels-selected-container .labels-selected {
  color: #333366;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;
}

.labels-selected-container .num-labels-selected {
  font-size: 16px;
  display: inline-block;
  background: #333366;
  color: #fff;
  border-radius: 20px;
  width: 35px;
  text-align: center;
  margin-right: 5px;
  vertical-align: top;
  margin-top: 10px;
}

.radio-container.horizontal {
  display: inline-block;
  padding-left: 15px;
}

#labels-cancel-radio:disabled ~ label {
  color: #999999;
}

.labels-selected-container .btn-primary {
  min-width: auto;
}

.shipping-history-showing-results {
  padding-bottom: 20px;
}

table.shipping-history,
.shipping-history th,
.shipping-history td {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.shipping-history td p {
  font-size: 12px;
  color: #000000;
}

tr.shipping-history-table-header th {
  color: #333366;
  font-size: 14px;
}

table.shipping-history thead tr:first-child {
  border-bottom: 2px solid #e71921;
}

.shipping-history td a,
.service-unavailable-link {
  color: #3573b1;
}

.shipping-history a.label-number {
  font-size: 12px;
}

.shipping-history label.checkbox-text.vertical {
  padding-top: 1px;
}

.shipping-history label {
  display: inline-block;
}

.shipping-history label input[type="checkbox"] {
  position: static;
}

.shipping-history label.checkbox-component {
  padding-left: 20px;
}

.total .gray-box-wrapper {
  margin-top: 0px;
}

table.shipping-history tr:first-child {
  border-top: none;
}

#duties-and-taxes {
  padding-left: 30px;
}

.label-total {
  background: #f7f7f7;
  padding-bottom: 16px;
  padding-left: 5px;
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
}

.shipping-history .label-error .error-message {
  display: block;
}

#shipping-history-empty-table {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.results-per-page {
  padding: 0px;
}

.results-per-page {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: right;
  font-size: 16px;
  padding-top: 10px;
  color: #333366;
}

.dropdown-selection .dropdown-menu {
  width: initial;
  left: 0px;
  position: absolute;
  min-width: 100%;
  overflow-y: auto;
  max-height: 200px;
}

.dropdown-selection {
  position: relative;
  background: #ffffff;
}

select.form-control {
  font-size: 14px !important;
}

select.dropdown,
#mobile-contact-select button.dropdown,
.results-dropdown-container .dropdown-selection button.dropdown,
.click-step-five-input-container .dropdown-selection.dropdown > button,
.scan-form-wrapper .scan-form-zip-code .dropdown > button,
.hazmatCategory .dropdown-selection button#hazmatDropDown {
  background-image: url("./click-n-ship/images/blue-caret-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 13px;
  background-size: 18px 18px;
  cursor: pointer;
}

.showing-results {
  padding-top: 10px;
  font-size: 16px;
  text-align: right;
  color: #333366;
}

.label-total {
  border-bottom: unset;
  width: 100%;
  float: left;
  background-color: #f7f7f7;
  margin: 0px;
  padding-bottom: 0px;
}

.labels-num p {
  float: right;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #333366;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.total-cost p {
  float: right;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  color: #333366;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.advanced-search-options #update-columns-desktop {
  padding-left: 15px;
}

#shipping-history-footer {
  padding-top: 10px;
  padding-bottom: 40px;
}

#shipping-history-table-pages-id {
  position: static;
}

@media only screen and (max-width: 767px) {
  .gray-box,
  #global-express-form-id {
    padding-left: 0px;
    padding-right: 0px;
  }

  .shipping-history-search-btn {
    display: none;
  }

  .adv-manage-table-columns .checkbox-wrap {
    display: inline-block;
    width: 50%;
  }

  .adv-manage-table-columns .checkboxes.container {
    height: 200px;
  }

  .adv-manage-table-columns .checkbox-container {
    padding-top: 5px;
  }

  #update-columns-desktop {
    display: none;
  }

  .adv-remove-link strong {
    display: none;
  }

  .advanced-search-options .radio-container.horizontal {
    padding-left: 5px !important;
    padding-top: 50px;
  }

  .adv-sort-labels .radio-button {
    display: none;
  }

  .adv-sort-labels .adv-sort-button,
  .adv-sort-labels .adv-clear-button,
  .adv-update-columns {
    min-width: 240px;
  }

  .ui-sortable .radio-button ~ label {
    padding-left: 5px;
  }

  .adv-remove-link {
    padding-left: 2px;
    margin-top: 52px;
  }

  #shipping-history-wrapper {
    overflow-x: auto;
    padding-left: 0px;
  }

  .shipping-history th:first-child,
  .shipping-history td:first-child {
    left: 0px;
    background: #fff;
    vertical-align: top;
    padding-top: 5px;
  }

  .shipping-history th:first-child:after,
  .shipping-history td:first-child:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 0px;
    width: 5px;
    height: 100%;
    background: linear-gradient(to right, #ededed, #fff);
  }

  .shipping-history td,
  .shipping-history th {
    position: static;
    display: table-cell;
    float: none;
    width: 25%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  table.shipping-history tr {
    border-top: 2px solid #ededed;
  }

  .shipping-history th a {
    display: flex;
    font-size: 12px;
  }

  .shipping-history th img {
    padding-left: 5px;
  }

  .labels-selected-container .btn-primary {
    margin-top: 20px;
  }

  .results-pagination li:not(.navigation) {
    display: inline-block;
  }

  .results-pagination-wrapper {
    text-align: center;
  }

  .gray-box .gray-box-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .global-express-service-type-price {
    width: 100%;
  }

  .global-express-form-container {
    padding-top: 15px;
  }

  .label-total-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .shipping-history label.checkbox-component {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 889px) {
  tr.shipping-history-table-header th,
  .labels-selected-container .radio-button ~ label,
  .labels-selected-container .labels-selected,
  .labels-selected-container .num-labels-selected {
    font-size: 12px;
  }

  labels-selected-container
    .radio-container.horizontal
    + .radio-container.horizontal {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .adv-sort-labels {
    padding-top: 25px;
  }
}

@media only screen and (min-width: 992px) {
  .adv-sort-labels {
    border-left: 1px solid #999999;
  }

  .labels-selected-container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .adv-remove-link {
    padding-left: 10px;
  }

  .advanced-search-options .radio-container.horizontal {
    padding-left: 0px;
  }

  .advanced-search-options
    .radio-container.horizontal
    + .radio-container.horizontal {
    padding-left: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .shipping-history th.text-md-right,
  .shipping-history td.text-md-right,
  .shipping-history td.text-right {
    padding-right: 15px;
  }

  .global-express-service-type-price {
    width: 350px;
  }

  .shipping-history label.checkbox-component {
    padding-top: 0px;
  }
}

.shipping-history th.shipping-history-select-all {
  background: #f7f7f7;
}

.shipping-history-table-force-wrap {
  max-width: 223px;
}

#go-button:disabled {
  color: #ffffff;
  background: #333366;
  border: 1px solid #333366;
}

/* END SHIPPING HISTORY */

/* START COMFIRMATION */

.confirmation-thankyou {
  padding-top: 30px;
}

.payment-confirmation {
  font-size: 14px;
  color: #595959;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  padding-top: 20px;
}

.payment-confirmation-info {
  padding-top: 20px;
}

.payment-confirmation-info p {
  display: block;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 0 0 0px;
}

.payment-confirmation-info p + p {
  padding-top: 0px;
}

.alert-message {
  color: #e71921;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 13px;
}

ul.print-labels-first-radio,
ul.print-labels-second-radio {
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0px;
}

input[type="checkbox"],
input[type="radio"] {
  vertical-align: top;
  margin: 3px 0 0;
}

.radio-container-print-labels input[type="radio"] {
  display: inline-block;
  float: left;
  top: 14px;
  outline: none;
  position: relative;
}

.print-labels-first-radio,
ul.print-labels-second-radio li {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  display: block;
  float: none;
}

.adobe-message {
  color: #333366;
  font-size: 13px;
}

.SCAN_Form_Container .gray-box .gray-box-wrapper {
  padding-left: 0px;
}

.print-labels-adobe-reader,
.print-labels-post-office-info,
.print-labels-checkbox,
.print-scan-form,
.label-details-first-checkbox {
  padding-top: 20px;
}

label.print-labels-checkbox {
  font-size: 14px;
}

.shipping-address p,
.package-details p,
.service p,
.label-number p {
  color: #333366;
}

.label-details-wrapper {
  padding-top: 25px;
}

.label-details-header {
  padding-top: 25px;
}

.label-details-first-checkbox {
  list-style: none;
  border-top: 2px solid #e71921;
  padding-left: 20px;
}

tr.label-details-checkbox-header {
  color: #333366;
  font-size: 16px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  text-align: left;
  font-weight: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

tr.label-details-checkbox-container {
  font-size: 12px;
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

table.label-details-select-all tr:first-child {
  border-bottom: 2px solid #e71921;
}

table.label-details-select-all,
.label-details-checkbox-header th,
.label-details-checkbox-container td {
  font-size: 14px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

td.label-number-content,
th.label-number-header {
  text-align: right;
}

td.label-details-row-checkbox {
  width: 130px;
}

.label-details-checkbox-container label.checkbox-text.vertical {
  width: 120px;
}

.confirmation-table-header p {
  color: #333366;
}

.confirmation-table-header label {
  padding-top: 0px;
}

.confirmation-item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.confirmation-item p + p {
  padding-top: 0px;
}

p.mobile-scan-form-shipping-address,
p.mobile-scan-form-package-details,
p.mobile-scan-form-service,
p.mobile-scan-form-price {
  color: #595959;
  font-size: 12px;
}

.list-items p + p {
  padding-top: 0px;
}

.list-items .column-header {
  padding-bottom: 10px;
}

/* START INFORMED DELIVERY MODAL */
.ID-yes-checked {
  display: none;
  padding-bottom: 10px;
}

.ID-yes-checked p {
  padding-left: 25px;
}

.ID-yes-checked .checkbox-wrap {
  padding-left: 25px;
  padding-top: 0px;
}

#cnsIDXSModal .expand-collapse-wrapper {
  padding-top: 10px;
}
/* END INFORMED DELIVERY MODAL */

/* OTHER MODALS */
#time-out-modal .modal-buttons,
#postage-due-modal .modal-buttons {
  text-align: center;
}

#gxgTermsConditions ol {
  padding: 0px;
}

#gxgTermsConditions li {
  list-style: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .radio-container-print-labels input[type="radio"] {
    display: inline-block;
    float: left;
    top: 2px;
    outline: none;
    position: relative;
  }

  .radio-container-print-labels {
    float: left;
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin: 0px 0px -3px;
  }

  label.checkbox-text.print-labels-checkbox p {
    padding-left: 30px;
  }

  .button-container.create-another-label {
    padding-left: 0px;
  }

  .confirmation-table-header + .row > div {
    padding-top: 20px;
  }

  .confirmation-item p {
    color: #000000;
  }

  .Payment_Confirmation_Container .btn-primary {
    min-width: 240px;
  }

  .confirmation-item label.checkbox-component {
    padding-top: 0px;
  }

  .shipping-history label.checkbox-component {
    padding-left: 15px;
  }

  .SCAN_Form_Container .gray-box .gray-box-wrapper {
    padding-left: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .button-container.create-another-label {
    float: right;
  }

  .confirmation-table {
    padding-top: 30px;
  }

  .confirmation-table-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #e71921;
  }
}

/* END CONFIRMATION */

/*START SCAN FORM OPEN */

.scan-form-open-header {
  padding-top: 30px;
}

.no-open-form {
  display: none;
}

td.actions-container-link a {
  float: left;
}

table.col-lg-12.col-md-12.col-sm-12.form-group.form-group.scan-form-details {
  margin-top: 20px;
}

p.scan-form-details-expiration-time-day {
  color: #333366;
}

button.request-remove.scan-form-label {
  float: left;
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 16px;
}

h2.label-total {
  float: right;
}

p.scan-form-label-total {
  font-size: 18px;
  color: #333366;
}

.gray-box.cancel-scan-form {
  padding-top: 15px;
}

.cancel-scan-form-content {
  padding-top: 30px;
  padding-bottom: 20px;
}

.checkbox-print-scan-form-label {
  padding-top: 20px;
}

.scan-form-table .request-remove {
  float: none;
}

.scan-form-item {
  padding-top: 15px;
  padding-bottom: 15px;
}

.scan-form-table-services {
  padding-bottom: 5px;
}

.open-scan-form-container .button-container .btn-primary {
  min-width: 265px;
}

.scan-form-item p + p {
  padding-top: 0px;
}

.scan-form-table-header p,
.scan-form-table-totals p {
  color: #333366;
}

.scan-form-item p {
  color: #000000;
}

p.mobile-scan-form-shipping-address,
p.mobile-scan-form-service,
p.mobile-scan-form-package-details,
p.mobile-scan-form-price {
  font-size: 12px;
  color: #595959;
}

#scan-form-modal .button-container + .button-container {
  margin-left: 30px;
}

#scan-form-modal .btn-primary {
  min-width: 235px;
}

#scan-remove-all-confirmation-modal .modal-buttons,
#scan-remove-confirmation-modal .modal-buttons {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .scan-form-table-header ~ .row > div,
  .scan-form-table-services div + div {
    padding-top: 20px;
  }

  .scan-form-wrapper .request-remove strong {
    display: none;
  }

  .scan-form-item p {
    color: #000000;
  }

  .modal-footer.scan-form {
    display: block;
  }

  #scan-form-modal .button-container + .button-container {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px) {
  .scan-form-table {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .scan-form-table-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #e71921;
  }

  p.scan-form-label-total {
    text-align: right;
  }

  #scan-remove-confirmation-modal.modal-dialog,
  #scan-remove-all-confirmation-modal.modal-dialog {
    width: 400px;
  }

  .modal-dialog.scan-form-confirmation-modal {
    max-width: 570px;
  }
}

/* END SCAN FORM OPEN */

/* START (SCAN) OPEN FORM */
.open-scan-form-container {
  padding-top: 15px;
}

/* START MODAL: SCAN FORM CONFIRMATION */
.modal-body.scan-form {
  padding-top: 30px;
}

.modal-footer.scan-form {
  border-top: none;
  padding: 0px 0px 0px 0px;
  justify-content: flex-start;
}

.modal-body.scan-form ul {
  padding-left: 30px;
  list-style: disc;
  padding-top: 16px;
}

.modal-body.scan-form p,
.modal-body.scan-form li + li {
  float: left;
  width: 100%;
  padding-top: 0px;
}

.modal-label {
  padding-top: 20px;
}

@media only scren and (min-width: 768px) {
  .modal-dialog.scan-form-confirmation-modal {
    max-width: 650px;
  }
}

/* END MODAL: SCAN FORM CONFIRMATION */

/* END (SCAN) OPEN FORM */

/* START VIEW LABEL */

.view-label-info {
  color: #000;
}

#view-label-container .service-type-table {
  padding-top: 26px;
}

#view-label-container .service-type-table-header {
  border-bottom: 2px solid #e71921;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

#view-label-container .service-type-table-header p {
  color: #333366;
}

#view-label-container .service-type-table-footer {
  border-top: 2px solid #333366;
  margin-top: 10px;
  padding-top: 5px;
}

#view-label-container .service-type-table-footer p {
  color: #333366;
}

#view-label-container .view-label-messages {
  padding-bottom: 60px;
}

#view-label-container .view-label-messages-header {
  border-bottom: 2px solid #e71921;
  padding-bottom: 15px;
}

#view-label-container .view-label-messages-header p {
  color: #333366;
}

#view-label-container .view-label-messages-header ~ .row {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #999999;
}

/* END VIEW LABEL */

/* START SHIPPING CART */
.shipping-cart-table-placeholder {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.shipping-cart-table {
  counter-reset: shipping-cart-table-counter;
}

.shipping-cart-number:before {
  content: counter(shipping-cart-table-counter);
}

.total-cart-items {
  display: none;
}

.shipping-cart-item {
  padding-top: 15px;
  padding-bottom: 15px;
  counter-increment: shipping-cart-table-counter;
}

.shipping-cart-table-services {
  padding-bottom: 5px;
}

.shipping-cart-table-totals {
  border-top: 2px solid #333366;
}

.shipping-cart-table-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #ededed;
}

.shipping-cart-table-footer p {
  color: #333366;
}

.shipping-cart-table-order-total {
  font-size: 22px;
}

button.inline-link-delete:after {
  content: "";
  background-image: url(./click-n-ship/images/close_x.svg);
  display: inline;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  padding-left: 26px;
  background-position: 9px 4px;
}

.Shipping_Cart_Container .btn-primary {
  min-width: 205px;
}

@media only screen and (max-width: 767px) {
  .shipping-cart-table-header + .shipping-cart-table-placeholder > div {
    padding-top: 20px;
  }

  .shipping-cart-edit-delete p {
    display: inline-block;
  }

  .mobile-shipping-cart-number {
    padding-right: 0px;
  }

  .mobile-shipping-cart-number p:before {
    content: counter(shipping-cart-table-counter);
  }

  .shipping-cart-item p {
    color: #000000;
    padding-top: 0px;
  }

  button.mobile-inline-link-delete:after {
    content: "";
    background-image: url(./click-n-ship/images/close_x.svg);
    display: inline;
    background-repeat: no-repeat;
    background-size: 13px 13px;
    padding-left: 26px;
    background-position: 4px 4px;
  }

  .shipping-cart-item .service-type-table {
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .shipping-cart-table {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .shipping-cart-table-header {
    padding-bottom: 15px;
    border-bottom: 2px solid #e71921;
  }

  .shipping-cart-item p + p {
    padding-top: 0px;
  }

  .Shipping_Cart_Container .button-wrapper {
    padding-left: 0px;
  }

  #shipping-cart-remove-confirmation-modal.modal-dialog,
  #shipping-cart-remove-all-confirmation-modal.modal-dialog {
    max-width: 410px;
  }
}

.shipping-cart-table-header p,
.shipping-cart-table-totals p {
  color: #333366;
}

p.mobile-shipping-cart-service,
p.mobile-shipping-cart-package-details {
  font-size: 12px;
  color: #595959;
  padding-top: 15px;
}

p.mobile-shipping-cart-package-details + p {
  padding-top: 0px;
}

#shipping-cart-remove-confirmation-modal .modal-buttons,
#shipping-cart-remove-all-confirmation-modal .modal-buttons,
#request-a-refund-modal .modal-buttons,
#cancel-a-refund-modal .modal-buttons {
  text-align: center;
}

/* END SHIPPING CART */

/* START MODAL ADDRESS BOOK */

.pagination-container {
  display: inline-block;
  text-align: center;
}

.inactive {
  cursor: context-menu;
}

button.header-tabs {
  font-size: 12px;
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

button.header-tabs.inactive {
  color: #d8d8d8;
  display: none;
  cursor: context-menu;
}

#name-header,
#company-header,
#location-header {
  color: #333366;
  font-size: 16px;
}

.groups-list .contact-group-header {
  padding-top: 20px;
}

.contact-group-header p {
  color: #333366;
  font-size: 20px;
  padding-left: 20px;
}

.contact-group-header p.active {
  padding-left: 16px;
  border-left: 4px solid #e71921;
}

.contact-groups {
  display: block;
}

.contact-groups div.row {
  padding-top: 10px;
}

.contact-groups .contact-group-name button {
  padding-left: 19px;
  font-size: 15px;
  color: black;
}

.contact-groups .contact-group-name.active button {
  padding-left: 15px;
  border-left: 4px solid #e71921;
}

.contact-sub-group.contact-group-name {
  padding-top: 10px;
  padding-left: 40px;
}

.ab-batch-message {
  padding-top: 5px;
}

#modal-address-book-table td,
#modal-address-book-table th {
  border-top: 1px solid #999999;
  padding-top: 15px;
  padding-bottom: 15px;
  vertical-align: middle;
}

#modal-address-book-table > thead > tr > th {
  vertical-align: top;
  border-bottom: none;
  font-size: 16px;
  color: #333366;
}

#modal-address-book-table thead {
  border-bottom: 2px solid #e71921;
  height: 50px;
}

#modal-address-book-table th {
  text-align: left;
}

#modal-address-book-table td {
  font-size: 13px;
}

#modal-address-book-table tr:first-child th,
#modal-address-book-table tr:first-child td {
  border-top: none;
}

#modal-address-book-table tbody tr:last-child {
  border-bottom: 1px solid #999999;
}

#modal-address-book-table tr {
  width: 100%;
  display: inline-table;
  table-layout: fixed;
  height: 40px;
}

#modal-address-book-table tbody {
  overflow-y: scroll;
  max-height: 351px;
  min-height: 60px;
  display: block;
}

#modal-address-book-table tbody::-webkit-scrollbar {
  width: 10px;
}

#modal-address-book-table tbody::-webkit-scrollbar-thumb {
  background: #888;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.results-label {
  color: #333366;
  font-size: 16px;
  padding-right: 15px;
}

.results-dropdown-container select.form-control {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  background: transparent url("./click-n-ship/images/blue-caret-down.svg")
    no-repeat;
  background-position: 80% 16px;
  background-size: 20px;
  cursor: pointer;
  color: #333366;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 100px;
}

.results-dropdown-container select.form-control {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.nav.nav-stacked {
  padding-left: 10px;
  display: inline-block;
  min-width: 90px;
}

.radio-button:checked:after {
  display: inline-block;
}

.dropdown-menu {
  min-width: 80px;
}

.results-dropdown-container {
  display: inline-flex;
  align-items: center;
}

#results-amount-address-book {
  padding-right: 40px;
}

#mobile-contact-select button:active,
#results-amount-address-book:active,
#service-type-dropdown-usa-id:active,
#service-type-dropdown-int-id:active {
  color: #333366;
  background-color: #fff;
  border-color: #333366;
  font-size: 15px;
}

.results-amount {
  padding-right: 40px;
}

#address-book .btn-primary {
  min-width: 175px;
}

#address-book-search-input {
  border-right: none;
}

.input-group-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ab-search-results-text {
  color: #333366;
}

#modal-address-book-table thead {
  display: block;
  padding-right: 25px;
}

@media only screen and (max-width: 767px) {
  #modal-address-book-table {
    margin-top: 15px;
  }

  #modal-address-book-table tbody {
    max-height: 274px;
  }

  #mobile-contact-select,
  .input-group .form-control {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    font-size: 14px;
  }

  #name-header,
  #company-header,
  #location-header {
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr {
    height: 55px;
    border-bottom: 1px solid #999999;
  }

  .results-dropdown-container {
    justify-content: center;
  }

  .pagination-container {
    padding-top: 20px;
  }

  .pagination-container .results-pagination li {
    display: inline-block;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    display: table-cell;
    width: 10%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #modal-address-book-table {
    border: 1px solid #999999;
  }

  #modal-address-book-table thead {
    padding-right: 10px;
  }

  #modal-address-book-table thead tr {
    height: auto;
  }

  #modal-address-book-table thead tr th {
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 45%;
  }

  #modal-address-book-table thead tr th:not(:last-child) {
    border: none;
  }

  #modal-address-book-table thead tr th:last-child,
  #modal-address-book-table tr td:last-child {
    display: block;
  }

  #modal-address-book-table tbody th {
    border-top: none;
  }

  #modal-address-book-table tr td {
    display: inline-block;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 0px;
    border-top: none;
    width: 45%;
  }

  #modal-address-book-table tr td:last-child {
    border: none;
    padding-bottom: 5px;
  }

  #modal-address-book-table tbody tr:last-child {
    border-bottom: none;
  }

  .modal-dialog.modal-xl {
    margin: 1.5rem;
    max-width: none;
  }

  .results-label-connect {
    display: none;
  }

  .dropdown-selection-connect {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  #modal-address-book-table tbody tr {
    height: 70px;
  }

  #modal-address-book-table tbody {
    padding-right: 15px;
  }

  #modal-address-book-table tbody th,
  #modal-address-book-table thead th:first-child {
    width: 9%;
  }

  #address-book .button-container:last-child {
    float: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .results-label {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  #name-header,
  #company-header,
  #location-header {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .pagination-container {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }

  .results-dropdown-container {
    width: 100%;
    justify-content: center;
  }

  .modal-dialog.modal-xl {
    max-width: none;
    width: 92%;
  }

  #address-book a.btn-primary,
  #address-book button.btn-primary {
    min-width: auto;
  }
}

@media only screen and (min-width: 1159px) {
  .pagination-container {
    width: 50%;
  }

  .results-dropdown-container {
    width: 50%;
  }
}

/* Styling for buttons that used to be anchor tags, and their related tags */
@media only screen and (max-width: 767px) {
  .package-items-desc-mobile {
    text-align: left;
  }
}

.button-link {
  background-color: transparent;
  border: none;
  color: #333366;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

.button-link.adv-remove-link {
  padding-left: 20px;
}

.button-link.expand-collapse,
.shipping-restrictions .button-link,
.shipping-prohibitions .button-link {
  width: 100%;
}

.shipping-restrictions .button-link,
.shipping-prohibitions .button-link {
  text-align: left;
}

.button-link:hover {
  color: #999999;
  opacity: 0.7;
  text-decoration: none;
}

.button-link:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  color: #333366;
  outline: 0;
  text-decoration: none;
}

/* LOYALTY */

/*
#loyaltybar {
  position: relative;
  display: inline-block;
  padding: 0.1em 0em 2em 0em;
  overflow: hidden;
  color: #333366;
  opacity: 1;
}

@media (max-width: 750px) {
  #loyaltybar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65%; /* Change this to change the height of the blue part of the LPA points bar
    background-color: #333366;
  }
}

@media (min-width: 751px) {
  #loyaltybar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%; /* Change this to change the height of the blue part of the LPA points bar
    background-color: #333366;
  }
}

#loyaltyProgress {
  /*height: calc(100% - 15px);
  width: 100%;
  height: 8%; /* Change this to change the height of the grey part of the LPA points bar
  padding-top: 0px;
  background-color: #ddd;
  /* -webkit-transform: perspective(300px) rotateX(-30deg);
	-o-transform: perspective(300px) rotateX(-30deg);
	-moz-transform: perspective(300px) rotateX(-30deg);
	-ms-transform:perspective(300px) rotateX(-30deg);
	transform:perspective(300px) rotateX(-30deg);
	
	-webkit-transform-origin: 0% 50%;
	 -o-transform-origin:0% 50%;
	 -moz-transform-origin:0% 50%;
	 transform-origin:0% 50%;
	 -ms-transform-origin:0% 50%;
   transform-origin:0% 50%;

   	-webkit-transform: perspective(300px) rotateX(-30deg);
	-o-transform: perspective(300px) rotateX(-30deg);
	-moz-transform: perspective(300px) rotateX(-30deg);
	-ms-transform:perspective(300px) rotateX(-30deg);
	transform:perspective(300px) rotateX(-30deg);
    
   	-webkit-transform-origin: -50% 0%;
	 -o-transform-origin:-50% 0%;
	 -moz-transform-origin:-50% 0%;
	 transform-origin:-50% 0%;
	 -ms-transform-origin:-50% 0%;
   transform-origin:-50% 0%; 

  transform: skew(-20deg);

  /*-webkit-transform-origin:100% 0;
	-ms-transform-origin: 100% 0;
	transform-origin: 100% 0;
	-webkit-transform: skew(-45deg);
	-ms-transform: skew(-45deg);
	transform:skew(-45deg);
}
*/

/* LABEL BROKER */

.label-broker-color {
  color: #333366;
}

#labelBrokerSuccessGroup {
  padding-left: 15px;
}

#label-broker-showing-results {
  margin-bottom: -10px;
  padding-bottom: 0px;
}

.label-broker-table {
  padding-left: 15px;
}

@media only screen and (min-width: 768px) {
  .label-broker-table ul {
    width: 480px;
  }
}

.results-pagination-wrapper {
  top: -9px;
}

#label-broker-results-pagination {
  margin-bottom: 0px;
  transform: scale(0.7);
}

.label-broker-border {
  border-bottom: 1px solid #d8d8d8;
}

.label-broker-border:first-of-type {
  border-top: 1px solid #d8d8d8;
}

li.label-broker-row {
  margin-left: 40px;
  padding-bottom: 15px;
  padding-top: 15px;
}

/* OTHER */

.smmainTextRed {
  color: red;
}

/*
@media only screen and (min-width: 576px) {
  #lpaModal {
    width: 520px !important;
  }
}
*/

.modal-open {
  overflow: scroll !important;
}

#global-error-section {
  padding: 0px;
}

.gxg-suspension-message {
  padding-top: 10px;
}
/*USPS CONNECT BANNER*/
#psbanner {
  position: relative;
  display: inline-block;
  padding: 1em 5em 1em 1em;
  overflow: hidden;
  color: #fff;
  z-index: 10;
}

#psbanner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 92%;
  height: 100%;
  background: #333366 !important;
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-15deg);
  -ms-transform: skew(-15deg);
  transform: skew(-15deg);
  border-right: 6px solid white;
  z-index: -1;
}

#bannerWrapper {
  margin-top: 20px;
}

#bannerWrapper:after {
  background: #f7f7f7;
  z-index: -1;
  font-weight: bold;
}

#psbannerText {
  padding-top: 15px;
  padding-left: 0px;
  font-size: 15px;
  color: #595959;
}

#psbannerTextWrapper {
  background: #f7f7f7;
}

#psbannerbluetext {
  margin-left: 20px;
}

#sameOrNextDay {
  font-size: 14px;
  font-weight: bold;
}

#expandableSectionWrapper {
  margin-left: 17px;
  margin-right: 25px;
  padding: 20px 60px 20px 15px;
}

#expandableBulletList {
  margin-left: 55px;
  padding-top: 10px;
  padding-bottom: 5px;
}

/*USPS CONNECT BANNER Mobile*/
@media only screen and (max-width: 700px) {
  #bannerWrapper * {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11px;
  }
  #sameOrNextDay {
    display: none;
  }
  #psbannerbluetext {
    margin-top: 50%;
  }

  #expandableSectionWrapper * {
    margin: 0 !important;
    padding: 0 !important;
  }
}

/*END USPS CONNECT BANNER*/

/* BANNERS */

#hide-postage-price-banner-wrapper,
#usps-connect-local-self-enrollment-banner-wrapper,
#usps-connect-local-after-self-enrollment-banner-wrapper,
#redirect-to-new-cns-banner-wrapper {
  padding-bottom: 10px;
  padding-top: 10px;
}

#hide-postage-price-banner-description-wrapper,
#usps-connect-local-self-enrollment-banner-description-wrapper,
#usps-connect-local-after-self-enrollment-banner-description-wrapper,
#redirect-to-new-cns-banner-description-wrapper {
  padding-left: 0px;
}

#hide-postage-price-banner-description,
#usps-connect-local-self-enrollment-banner-description,
#usps-connect-local-after-self-enrollment-banner-description,
#redirect-to-new-cns-banner-description {
  display: inline;
}

@media (max-width: 767px) {
  #usps-connect-local-self-enrollment-banner-description-wrapper,
  #redirect-to-new-cns-banner-description-wrapper {
    padding-bottom: 10px;
  }
}

/* BANNERS */

/*USPS Local Dropdown menu step 5*/
.dropdownLI:hover .dropdownSameOrNextDayShipping {
  color: white !important;
}
/*END USPS Local Dropdown menu step 5*/

/* USPS Connect Local - carrier pickup */
.carrierPickupItemEligible {
  background-color: #f7f7f7;
}

.carrierPickupDividerTop {
  margin-bottom: 30px;
  margin-top: 15px;
}

.carrierPickupWrapper {
  padding-bottom: 30px;
}

.carrierPickupHeader {
  font-size: 15px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carrierPickupHeader,
.carrierPickupInfo strong {
  color: #333366;
}

@media (min-width: 901px) {
  .carrierPickupInfoDesktopWrapper {
    display: flex;
  }
}

@media (max-width: 900px) {
  .carrierPickupInfoMobileWrapper {
    padding-left: 0px !important;
  }
}

.carrierPickupAdditionalInfo {
  font-size: 12px;
}

.carrierPickupButtonWrapper {
  display: inline-block;
  min-width: 191px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-top: 10px;
}

.carrierPickupInputs {
  padding-left: 15px;
}

.carrierPickupRadioSpacing {
  margin-bottom: 15px;
}

.carrierPickupInputSpacing {
  padding-top: 15px;
}

#carrierPickupAdditionalInstructions {
  font-size: 14px;
}

.row.carrierPickupLocationWrapper {
  margin-left: 0px;
  margin-right: 0px;
}

.carrierPickupAddress,
.carrierPickupAddressInfo,
.carrierPickupDate,
.carrierPickupDateInfo,
.carrierPickupConfirmationNumber,
.carrierPickupConfirmationNumberInfo {
  padding-left: 0px !important;
}

.carrierPickupAddressInfo {
  padding-top: 0px;
}

@media (min-width: 768px) {
  .carrierPickupAddress,
  .carrierPickupAddressInfo,
  .carrierPickupDate,
  .carrierPickupDateInfo,
  .carrierPickupConfirmationNumber,
  .carrierPickupConfirmationNumberInfo {
    padding-top: 0px;
  }

  .carrierPickupAddress {
    flex: 0 0 14%;
    max-width: 14% !important;
  }

  .carrierPickupAddressInfo {
    flex: 0 0 26%;
    max-width: 26% !important;
  }

  .carrierPickupDate {
    flex: 0 0 11%;
    max-width: 11% !important;
  }

  .carrierPickupDateInfo {
    flex: 0 0 18%;
    max-width: 18% !important;
  }

  .carrierPickupConfirmationNumber {
    flex: 0 0 18%;
    max-width: 18% !important;
  }

  .carrierPickupConfirmationNumberInfo {
    flex: 0 0 13%;
    max-width: 13% !important;
  }
}

.carrierPickupDividerBottom {
  margin-bottom: 15px;
  margin-top: 0px;
}
.supplyEdit {
  margin-left: 20px;
}

/* .free-shipping-modal {
  margin: 10px;
} */
/* END USPS Connect Local - carrier pickup */

/* START USPS Connect Local Page */

.connectLocalNavbar {
  margin-top: 20px;
  background-color: #f1f1f1;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.connectButtonSidebar {
  border-radius: 0rem !important;
  background-color: transparent !important;
  color: #333366 !important;
  width: 100%;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 10px;
  border: none;
  text-align: left;
  font-size: 14px;
}

@media (max-width: 767px) {
  .connectButtonSidebar {
    text-align: center;
  }
}

.connectButtonSelected {
  background-color: #c8c8c8 !important;
}

.connectButtonNotSelected {
  background-color: transparent !important;
}

.connectPagePadding {
  padding-left: 20px;
}

.connectLocalTitle {
  padding-top: 20px;
}

.connectLocalDescription {
  padding-bottom: 25px;
}

.connectLocalBenefits {
  padding-top: 25px;
}

.connectLocalSubTitle {
  font-size: 14px;
  padding-top: 15px;
}

.connectLocalBottom {
  padding-bottom: 25px;
}

.supportedLocationsButtonWrapper {
  padding-top: 41px;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .supportedLocationsButtonWrapper {
    padding-top: 41px;
  }
}

.supportedLocationsButton {
  width: 200px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .supportedLocationsButton {
    width: 100px;
    font-weight: bold;
  }
}

.withinPadding {
  padding-top: 10px !important;
}

.resultsNoPadding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 10px;
}

.resultsMarginTop {
  margin-top: 20px;
}

.pPadding {
  padding-top: 2px !important;
}

.resultsContainer {
  padding-bottom: 10px;
}

.pageNumberPadding {
  padding-top: 20px;
}

.resultsConnectPaginationWrapper {
  margin-top: 20px;
}

@media (max-width: 767px) {
  .resultsConnectPaginationWrapper {
    text-align: center;
  }
}

/* END USPS Connect Local Page */

/* START Post Office Info */

.postOfficeBorder {
  border-style: solid;
  border-color: #f1f1f1;
  border-top-width: 1.5px;
  border-bottom-width: 1px;
  border-left-width: 2px;
  border-right-width: 2px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 50px;
  padding-top: 10px;
  overflow-x: hidden;
}

.postOfficeBorder.postOfficeBorderClear {
  border-width: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-top: 0px;
}

.row .postOfficeHoursPadding {
  padding-left: 30px;
}

.textParking {
  color: black;
  margin-top: 5px;
}

.signUpModalTextPad {
  padding-left: 17px;
  padding-top: 5px;
}

.signUpModalHeaders {
  font-size: 16px;
  font-weight: bold;
}

/* END Post Office Info */
/*HAZMAT */
.hazmatContainer {
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  box-shadow: 0px 5px 8px #d8d8d8;
  margin-top: 40px;
  padding: 20px;
}

.hazmatHeader {
  color: #333366;
  margin-bottom: 10px;
}

.hazardQuestion {
  margin-top: 10px;
  margin-bottom: 10px;
}

.linkUnderline {
  text-decoration: underline;
}

.hazmatChevron {
  background-image: url(/src/click-n-ship/images/blue-caret-down.svg);
  background-repeat: no-repeat;
}

.hazmatCategory {
  margin-top: 20px;
}
/* START: TLC */

.submit-hs-tariff-code-wrapper {
  display: flex;
  margin-top: 15px;
}

@media only screen and (max-width: 991px) {
  .submit-hs-tariff-code-wrapper {
    justify-content: center;
  }

  #submit-hs-tariff-code-button {
    width: 100%;
  }
}

@media (min-width: 576px) {
  #modal-tlc {
    max-width: 765px;
  }
}

.field-length {
  display: flex;
  justify-content: right;
}

#tlc-lookup-paragraph {
  margin-bottom: 30px;
  margin-top: 10px;
}

#tlc-lookup-add-more-details {
  padding-top: 7px;
}

#tlc-lookup-tell-us-more,
#tlc-lookup-dimensions-text {
  margin-top: 26px;
}

.tlc-lookup-dimensions.form-group {
  padding-top: 5px;
}

.tlc-quantity-input {
  padding-right: 20px;
}

.tlc-quantity-btn-wrapper {
  align-items: flex-end;
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
}

.tlc-quantity-btn {
  padding-bottom: 9px;
  padding-top: 9px;
}

#setWeightInOzTlc,
#setWeightInOzTlcUnitsId {
  margin-top: 8px;
}

.button-wrapper.tlc-button-wrapper {
  padding-bottom: 0px;
}

@media (min-width: 576px) {
  #modal-tlc-hs-tariff,
  #modal-tlc-prohibited {
    max-width: 700px;
  }
}

#tlc-no-hs-tariff-allowed-text {
  padding-bottom: 20px;
}

#modal-tlc-prohibited .modal-body .body-content p {
  padding-bottom: 22px;
}

#modal-tlc-prohibited .modal-body .body-content p + p {
  padding-top: 0px;
}

label.checkbox-component[for="tlc-checkbox"] {
  padding-top: 0px;
}

#tlc-estimate-text {
  padding-top: 26px;
}

.tlc-estimate-table {
  padding-bottom: 15px;
  padding-top: 15px;
}

.tlc-estimate-table .table {
  margin-bottom: 0px;
}

table tbody tr td.tlc-table-column {
  border-top: none;
  padding-left: 25px;
}

.tlc-estimate-table .table .tlc-table-column-cost {
  text-align: right;
  padding-right: 25px;
}

#tlc-summary-row {
  background: #f7f7f7;
}

.tlc-button-wrapper {
  padding-bottom: 30px;
}

.btn-tlc-link {
  padding-left: 15px;
  text-decoration: underline;
}

@media (min-width: 576px) {
  #modal-tlc-estimate {
    max-width: 735px;
  }
}

@media (max-width: 767px) {
  .package-items-table .package-items-tlc-desc,
  .package-items-table .package-items-tlc-costs {
    width: 33.333333%;
  }

  .package-items-table .tlc-column {
    text-align: center;
  }
}

#tlc-itemized-last-row {
  border-top: 2px solid #333366;
}

#tlc-label-summary-wrapper {
  padding-left: 0px;
  padding-right: 0px;
}

.tlc-label-summary {
  margin-bottom: 0px;
  margin-top: 20px;
}

.modal-body ul.tlc-label-summary {
  padding-left: 0px;
}

#tlc-estimate-email-header {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  #tlc-email-button-container {
    padding-top: 23px;
  }
}

@media only screen and (max-width: 767px) {
  .tlc-email-confirmation-text,
  .tlc-email-error {
    margin-top: 10px;
  }
}

table thead tr th.table-col-align-left,
table tbody tr td.table-col-align-left {
  text-align: left;
}

.tlc-email-confirmation-text {
  color: rgb(87, 186, 149);
  padding-top: 5px;
}

/* END: TLC */

/* START: TDV Step 8 */

select.dropdownCountry,
#mobile-contact-select button.dropdown,
.results-dropdown-container .dropdown-selection button.dropdown,
.click-step-five-input-container .dropdown-selection.dropdown > button,
.scan-form-wrapper .scan-form-zip-code .dropdown > button {
  background-image: url("./click-n-ship/images/blue-caret-down.svg");
  background-repeat: no-repeat;
  background-position: right 10px top 13px;
  background-size: 18px 18px;
  cursor: pointer;
  width: 95%;
}

select.form-controlCountry {
  font-size: 14px !important;
  width: 85% !important;
}

.results-dropdown-container select.form-controlCountry {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  background: transparent url("./click-n-ship/images/blue-caret-down.svg")
    no-repeat;
  background-position: 80% 16px;
  background-size: 20px;
  cursor: pointer;
  color: #333366;
  margin-left: 10px;
  margin-bottom: 0px;
  width: 100px;
}

.results-dropdown-container select.form-controlCountry {
  font-family: "HelveticaNeueW02-55Roma", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

textarea.form-controlInputLabel {
  width: 90% !important;
}

input.form-controlTotalItemValue {
  max-width: 85% !important;
}

input.form-controlItemQty {
  max-width: 90% !important;
}

input.form-controlHSTariff {
  width: 85% !important;
}

.form-groupPackageWeight {
  max-width: 90% !important;
}

.btn-primaryHSTariff {
  width: 250px;
}

/* END TDV STEP 8 */
