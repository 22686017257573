.free-Shipping-item {
  width: 100%;
  display: flex;
  min-height: 50px;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
}

.image-container {
  width: 23%;
  padding-right: 15px;
}

.freeSuppliesImg {
  width: 100%;
  height: 100%;
}

.detail {
  width: 50%;
}
.detail p {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  margin: 0;
}

.supname,
.supdesc,
.supQuanHeader {
  color: #333366;
  font-size: 12px;
}
.shippingHeader {
  color: #333366;
  font-size: 14px;
}
.supdesc {
  margin: 0;
  padding: 0;
}
.supdimension {
  font-size: 10px;
}
.quantity {
  align-items: top;
  margin-top: 0;
  padding-top: 0;
}

.quantity p {
  font-family: "HelveticaNeueW02-75Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
.supMax {
  font-size: 10px;
  margin: 0;
  padding: 0;
}

.supInput {
  display: flex;
  border: solid 1px rgb(192, 186, 186);
  margin-left: 12px;
  border-radius: 10px;
  max-width: 12%;
  max-height: 10%;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
}
.supInput > input {
  width: fit-content;
}
.arrows {
  align-items: center;
}
.arrows p {
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: #333366;
}
.supplies-next-btn {
  float: right;
}
.quant {
  width: 27%;
  align-items: center;
  justify-content: center;
  padding-left: 9%;
}

.supply-header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid red;

  padding: 0px 20px;
}

.selected-Item {
  border-bottom: 1px solid gray;
}
.shipping-section {
  margin: 10% 0;
}
.footer-btns {
  margin: 5% 0;
  display: flex;
  justify-content: space-between;
}

.selected-input-quant:focus {
  outline: none;
}
.radio-shipping {
  margin: 2%;
  display: block;
  width: 40%;
}
.radio-input {
  display: flex;
  width: 100%;
}
.parcel-select {
  margin: 0 5px;
}

.arrival-status {
  margin-left: 5px;
}
.radio-selector {
  cursor: pointer;
}
.tooltip-selector {
  cursor: pointer;
}
.review {
  padding-top: 5px;
  padding-bottom: 10px;
}
.need_shipping-supplies {
  color: #333366;
  margin: 10px 0 0 0;
}
.view-all-btn {
  margin: 5px 0 20px 0;
}
